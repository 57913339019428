export default {
  'course.property.attr': '课程属性：',
  'course.property.skills': '你将学到的技能：',
  'course.property.progress': '学习进度：',

  'course.card.target.title': '目标受众',
  'course.card.includes.title': '本课程包括内容',

  'course.syllabus.title': '课程大纲'
};
