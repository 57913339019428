import ant from '@/assets/images/common/chains/ant.png';
import aptos from '@/assets/images/common/chains/aptos.png';
import astar from '@/assets/images/common/chains/astar.png';
import binance from '@/assets/images/common/chains/binance.png';
import chain33 from '@/assets/images/common/chains/chain33.png';
import conflux from '@/assets/images/common/chains/conflux.png';
import dfinity from '@/assets/images/common/chains/dfinity.png';
import ethereum from '@/assets/images/common/chains/ethereum.png';
import fisco from '@/assets/images/common/chains/fisco.png';
import flow from '@/assets/images/common/chains/flow.png';
import iris from '@/assets/images/common/chains/iris.png';
import nervos from '@/assets/images/common/chains/nervos.png';
import polygon from '@/assets/images/common/chains/polygon.png';
import sui from '@/assets/images/common/chains/sui.png';
import xdc from '@/assets/images/common/chains/xdc.png';

export enum EChainType {
  'Ethereum' = 'ethereum',
  'BNB Chain' = 'binance',
  'Chain33' = 'chain33',
  'Conflux' = 'conflux',
  'Dfinity' = 'dfinity',
  'Nervos' = 'nervos',
  'Polygon' = 'polygon',
  'Flow' = 'flow',
  'FISCO BCOS' = 'fisco',
  'Aptos' = 'aptos',
  'AntChain' = 'ant',
  'Astar' = 'astar',
  'XDC' = 'xdc',
  'IRISnet' = 'iris',
  'Sui' = 'sui'
}

export const chains = Object.values(EChainType);

export const CHAIN_TYPE_ICON_MAP: Record<string, string> = {
  [EChainType.Ethereum]: ethereum,
  [EChainType['BNB Chain']]: binance,
  [EChainType.Chain33]: chain33,
  [EChainType.Conflux]: conflux,
  [EChainType.Dfinity]: dfinity,
  [EChainType.Nervos]: nervos,
  [EChainType.Polygon]: polygon,
  [EChainType.Flow]: flow,
  [EChainType['FISCO BCOS']]: fisco,
  [EChainType.Aptos]: aptos,
  [EChainType.AntChain]: ant,
  [EChainType.XDC]: xdc,
  [EChainType.IRISnet]: iris,
  [EChainType.Sui]: sui,
  [EChainType.Astar]: astar
};

export const CHAIN_TYPE_NAME_MAP: Record<string, string> = {
  [EChainType.Ethereum]: 'Ethereum',
  [EChainType['BNB Chain']]: 'BNB Chain',
  [EChainType.Chain33]: 'Chain33',
  [EChainType.Conflux]: 'Conflux',
  [EChainType.Dfinity]: 'Dfinity',
  [EChainType.Nervos]: 'Nervos',
  [EChainType.Polygon]: 'Polygon',
  [EChainType.Flow]: 'Flow',
  [EChainType['FISCO BCOS']]: 'FISCO BCOS',
  [EChainType.Aptos]: 'Aptos',
  [EChainType.AntChain]: 'AntChain',
  [EChainType.XDC]: 'XDC',
  [EChainType.IRISnet]: 'IRISnet',
  [EChainType.Sui]: 'Sui',
  [EChainType.Astar]: 'Astar'
};
