exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-auth-callback-center-tsx": () => import("./../../../src/pages/authCallbackCenter.tsx" /* webpackChunkName: "component---src-pages-auth-callback-center-tsx" */),
  "component---src-pages-courses-course-id-chapter-sequence-index-tsx": () => import("./../../../src/pages/courses/[courseId]/[chapterSequence]/index.tsx" /* webpackChunkName: "component---src-pages-courses-course-id-chapter-sequence-index-tsx" */),
  "component---src-pages-courses-course-id-index-tsx": () => import("./../../../src/pages/courses/[courseId]/index.tsx" /* webpackChunkName: "component---src-pages-courses-course-id-index-tsx" */),
  "component---src-pages-courses-index-tsx": () => import("./../../../src/pages/courses/index.tsx" /* webpackChunkName: "component---src-pages-courses-index-tsx" */),
  "component---src-pages-courses-old-tsx": () => import("./../../../src/pages/courses-old.tsx" /* webpackChunkName: "component---src-pages-courses-old-tsx" */),
  "component---src-pages-demo-interactive-learning-tsx": () => import("./../../../src/pages/demo/interactive-learning.tsx" /* webpackChunkName: "component---src-pages-demo-interactive-learning-tsx" */),
  "component---src-pages-demo-video-based-tsx": () => import("./../../../src/pages/demo/video-based.tsx" /* webpackChunkName: "component---src-pages-demo-video-based-tsx" */),
  "component---src-pages-docs-tsx": () => import("./../../../src/pages/docs.tsx" /* webpackChunkName: "component---src-pages-docs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-ssr-tsx": () => import("./../../../src/pages/ssr.tsx" /* webpackChunkName: "component---src-pages-ssr-tsx" */),
  "component---src-pages-tos-tsx": () => import("./../../../src/pages/tos.tsx" /* webpackChunkName: "component---src-pages-tos-tsx" */)
}

