import cn from 'classnames';
import React from 'react';

import { useAppStore } from '@/store/appStore';
interface IAvatarProps {
  className?: string;
  size: 'big' | 'small';
}
export function Avatar(props: IAvatarProps) {
  const { userInfo } = useAppStore();
  const { className, size } = props;
  return (
    <div
      className={cn(
        className,
        size === 'big' && 'w-[92px] text-[40px] sm:text-[50px]',
        size === 'small' && 'w-7 text-base sm:text-lg',
        'flex aspect-square items-center justify-center rounded-full bg-[linear-gradient(90deg,#256BEF_20.9472%,#50F5FF_100%)] bg-cover bg-center text-white'
      )}
      style={
        userInfo?.avatar
          ? {
              backgroundImage: `url(${userInfo.avatar})`
            }
          : {}
      }
    >
      {userInfo?.avatar ? '' : userInfo?.username?.slice(0, 1)?.toUpperCase()}
    </div>
  );
}
