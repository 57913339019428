import cn from 'classnames';
import React from 'react';

import CompletedIcon from '@/assets/images/courses/completed.inline.svg';
import LockIcon from '@/assets/images/courses/lock.inline.svg';
import { CircleProgress } from '@/components/common/CircleProgress';

import { SectionStatus } from './SectionSyllabus';
interface ISyllabusProgressIconProps {
  className?: string;
  sectionStatus: SectionStatus;
  sectionProgress: number;
  isHoverOnSection: boolean;
}
export function SyllabusProgressIcon(props: ISyllabusProgressIconProps) {
  const { className, sectionStatus, sectionProgress, isHoverOnSection } = props;
  return (
    <div className={cn(className, 'mr-2')}>
      {sectionStatus === 'locked' && (
        <LockIcon
          color={
            isHoverOnSection && sectionStatus !== 'locked' ? 'white' : '#97A2B8'
          }
        />
      )}
      {sectionStatus === 'unlocked' && (
        <div className="flex h-7 w-7 items-center justify-center">
          <CircleProgress
            size="26"
            progress={sectionProgress}
            progressColor={isHoverOnSection ? '#fff' : '#3E81FF'}
            textColor={isHoverOnSection ? '#fff' : '#3E81FF'}
            bgColor={isHoverOnSection ? '#ffffff99' : '#3E81FF80'}
            textStyle={{ font: '9rem Poppins' }}
          />
        </div>
      )}
      {sectionStatus === 'completed' && (
        <div className="flex h-7 w-7 items-center justify-center">
          <CompletedIcon
            width={26}
            height={26}
            color={isHoverOnSection ? '#fff' : '#50F5FF'}
          />
        </div>
      )}
    </div>
  );
}
