import { domAnimation, LazyMotion } from 'framer-motion';
import { GA4_TRACK_ID } from 'gatsby-env-variables';
import React from 'react';
import ReactGA from 'react-ga4';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';

import { I18nextProvider } from '@/i18n';

interface RootElementProps {
  children: JSX.Element;
}

ReactGA.initialize(GA4_TRACK_ID);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      retry: false
    }
  }
});

export function RootElement(props: RootElementProps) {
  const { children } = props;

  return (
    <I18nextProvider>
      <QueryClientProvider client={queryClient}>
        <ToastContainer
          autoClose={5000}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
          draggable={false}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
        />
        <LazyMotion features={domAnimation}>{children}</LazyMotion>
      </QueryClientProvider>
    </I18nextProvider>
  );
}
