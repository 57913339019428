/* eslint-disable react/prop-types */
import * as SliderPrimitive from '@radix-ui/react-slider';
import cn from 'classnames';
import * as React from 'react';

import CourseSliderThumb from '@/assets/images/courses/course-slider-thumb.inline.svg';
export const ProgressSlider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={cn(
      'relative flex w-[250px] touch-none select-none items-center sm:w-[532px]',
      className
    )}
    {...props}
  >
    <SliderPrimitive.Track className="relative h-1 w-full grow overflow-hidden rounded-full bg-[rgba(37,107,239,0.20)]">
      <SliderPrimitive.Range className="absolute h-full bg-[linear-gradient(270deg,#37FFE7_0%,#2755AD_100%)]" />
    </SliderPrimitive.Track>
    <SliderPrimitive.Thumb className="focus:outline-none">
      <CourseSliderThumb className="z-[1000000] block" />
    </SliderPrimitive.Thumb>
  </SliderPrimitive.Root>
));
