import cn from 'classnames';
import React from 'react';

import Hot from '@/assets/images/courses/hot.inline.svg';
import { CHAIN_TYPE_ICON_MAP, CHAIN_TYPE_NAME_MAP } from '@/utils/chains';

export interface ICourseTag {
  name: string;
  type: 'HOT' | 'BEGINNER' | 'LANG' | 'CHAIN' | 'OTHER';
}

interface IAttributeTagProps extends ICourseTag {
  className?: string;
}
export function AttributeTag(props: IAttributeTagProps) {
  const { className, name, type } = props;
  if (!name) return <></>;
  return (
    <div
      key={name}
      className={cn(
        className,
        'flex items-center justify-center gap-x-[6px] rounded px-[15px] py-[6px]',
        type === 'HOT' && 'bg-[#9B82FF33] text-[#9B82FF]',
        type === 'BEGINNER' && 'bg-[#8BFFE333] text-[#8BFFE3]',
        type === 'LANG' && 'bg-[#77CEFF33] text-[#77CEFF]',
        type === 'CHAIN' && 'bg-[#D1FF9633] text-[#D1FF96]',
        (!type || type === 'OTHER') && 'bg-[#FD98FF33] text-[#FD98FF]'
      )}
    >
      {type === 'HOT' && <Hot />}
      {type === 'CHAIN' && (
        <img
          src={CHAIN_TYPE_ICON_MAP[name]}
          alt="chain logo"
          className="!h-4"
        />
      )}
      <span className="flex h-5 items-center justify-center pt-[2px] text-[14px]">
        {type === 'CHAIN' ? CHAIN_TYPE_NAME_MAP[name] : name}
      </span>
    </div>
  );
}

export function getTags(courseDetail?: API.CourseDTO, limit?: number) {
  const tags: ICourseTag[] = (courseDetail?.tags || []).map((item) => ({
    type: 'OTHER',
    name: item
  }));
  if (courseDetail?.difficultyLevel) {
    // 适用人群
    tags.unshift({
      type: 'BEGINNER',
      name: courseDetail.difficultyLevel
    });
  }
  if (courseDetail?.chains?.length) {
    // 链数据
    for (const chain of courseDetail.chains) {
      tags.unshift({
        type: 'CHAIN',
        name: chain
      });
    }
  }
  if (courseDetail?.programmingLanguages?.length) {
    for (const language of courseDetail.programmingLanguages) {
      tags.unshift({
        type: 'LANG',
        name: language
      });
    }
  }
  if (courseDetail?.hot) {
    tags.unshift({
      type: 'HOT',
      name: 'Hot'
    });
  }
  if (limit === undefined || tags.length <= limit) {
    return tags;
  }
  return extractTags(tags, limit);
}

function extractTags(tags: ICourseTag[], limit: number): ICourseTag[] {
  const hotTagDb = tags.filter((item) => item.type === 'HOT') || [];
  const chainTagDb = tags.filter((item) => item.type === 'CHAIN') || [];
  const langTagDb = tags.filter((item) => item.type === 'LANG') || [];
  const beginnerTagDb = tags.filter((item) => item.type === 'BEGINNER') || [];
  const otherTagDb = tags.filter((item) => item.type === 'OTHER') || [];

  const tempHotArray: ICourseTag[] = [];
  const tempChainArray: ICourseTag[] = [];
  const tempLangArray: ICourseTag[] = [];
  const tempBeginnerArray: ICourseTag[] = [];
  let total = 0;
  // 至多需要执行四轮
  for (let i = 0; i < limit; i++) {
    if (hotTagDb.length) {
      tempHotArray.push(hotTagDb.shift() as ICourseTag);
      total++;
      if (total === limit) {
        break;
      }
    }
    if (langTagDb.length) {
      tempLangArray.push(langTagDb.shift() as ICourseTag);
      total++;
      if (total === limit) {
        break;
      }
    }
    if (chainTagDb.length) {
      tempChainArray.push(chainTagDb.shift() as ICourseTag);
      total++;
      if (total === limit) {
        break;
      }
    }
    if (beginnerTagDb.length) {
      tempBeginnerArray.push(beginnerTagDb.shift() as ICourseTag);
      total++;
      if (total === limit) {
        break;
      }
    }
  }

  if (total === limit) {
    return [
      ...tempHotArray,
      ...tempLangArray,
      ...tempChainArray,
      ...tempBeginnerArray
    ];
  } else if (total < limit) {
    return [
      ...tempHotArray,
      ...tempLangArray,
      ...tempChainArray,
      ...tempBeginnerArray,
      ...otherTagDb
    ].slice(0, limit);
  }
  return [];
}
