import cn from 'classnames';
import { m } from 'framer-motion';
import React from 'react';

import DescIconOne from '@/assets/images/home/description/desc-icon-1.inline.svg';
import DescIconTwo from '@/assets/images/home/description/desc-icon-2.inline.svg';
import DescIconThree from '@/assets/images/home/description/desc-icon-3.inline.svg';
import DescIconFour from '@/assets/images/home/description/desc-icon-4.inline.svg';
import eduCourseScreenShot from '@/assets/images/home/description/edu-course-screenshot.png';
import { useTranslation } from '@/i18n';

interface SectionDescriptionProps {
  className?: string;
}

export function SectionDescription(props: SectionDescriptionProps) {
  const { className } = props;

  const { t } = useTranslation();

  const SectionDescriptions = [
    {
      icon: <DescIconOne />,
      title: t('home.section.two.description1.title'),
      desc: t('home.section.two.description1.desc')
    },
    {
      icon: <DescIconTwo />,
      title: t('home.section.two.description2.title'),
      desc: t('home.section.two.description2.desc')
    },
    {
      icon: <DescIconThree />,
      title: t('home.section.two.description3.title'),
      desc: t('home.section.two.description3.desc')
    },
    {
      icon: <DescIconFour />,
      title: t('home.section.two.description4.title'),
      desc: t('home.section.two.description4.desc')
    }
  ];

  return (
    <section className={cn('pb-8 sm:py-12 md:mt-16 lg:py-16', className)}>
      <div className="page-layout !min-h-0">
        <div className="max-w-7xl items-end justify-between sm:flex sm:pr-6 lg:pr-8">
          <m.h2
            initial={{ opacity: 0, scaleX: 0.9 }}
            whileInView={{ opacity: 1, scaleX: 1 }}
            transition={{
              type: 'spring',
              duration: 1.5,
              delay: 0.3
            }}
            className="text-center font-Manrope text-lg font-bold capitalize lg:text-[48px] lg:leading-[72px]"
          >
            {t('home.section.two.title')}
          </m.h2>
        </div>
        <div className="mt-8 grid grid-cols-1 gap-8 md:mt-12 lg:grid-cols-2 lg:gap-16">
          <div className="flex items-center">
            <m.div
              initial={{ opacity: 0, translateX: '-10%' }}
              whileInView={{ opacity: 1, translateX: '0)' }}
              transition={{
                type: 'spring',
                duration: 0.8
              }}
              className="relative w-full rounded-[10px] bg-[#404A5E] p-2 md:p-3"
            >
              <img
                src={eduCourseScreenShot}
                alt="Edu Course ScreenShot"
                className="h-full w-full rounded-[10px]"
              />
            </m.div>
          </div>

          <div>
            {SectionDescriptions.map((item, index) => (
              <m.div
                initial={{ opacity: 0, translateY: '-10%' }}
                whileInView={{ opacity: 1, translateY: '0' }}
                transition={{
                  type: 'spring',
                  duration: 0.8,
                  delay: 0.1 * index
                }}
                key={item.title}
                className="mt-7 first:mt-0"
              >
                <div className="flex items-center gap-2">
                  {item.icon}
                  <h2 className="pt-1 text-base font-semibold capitalize md:text-xl md:leading-10">{`${
                    index + 1
                  }. ${item.title}`}</h2>
                </div>
                <p className="mt-2 font-Manrope text-xs leading-6 text-[#97A2B8] md:text-base">
                  {item.desc}
                </p>
              </m.div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
