import cn from 'classnames';
import { m } from 'framer-motion';
import { navigate } from 'gatsby';
import React, { useMemo } from 'react';

import ExternalLink from '@/assets/images/common/external-link.inline.svg';
import BG1 from '@/assets/images/home/course-track-1.png';
import BG2 from '@/assets/images/home/course-track-2.png';
import BG3 from '@/assets/images/home/course-track-3.png';
import { useTranslation } from '@/i18n';

interface SectionCourseTrackProps {
  className?: string;
}

interface ICourseCard {
  title: string;
  buttonText: string;
  // link不填代表课程
  buttonLink?: string;
  bg: string;
  bgCls?: string;
}

export function SectionCourseTrack(props: SectionCourseTrackProps) {
  const { className } = props;

  const { t } = useTranslation();
  const cards: ICourseCard[] = useMemo(() => {
    return [
      {
        title: 'Web3 Course 101 - Beginners',
        buttonText: t('common.get_start'),
        buttonLink: '/courses/101',
        bg: BG1,
        bgCls: 'bg-[linear-gradient(256.89deg,#3d0789_-80.49%,#010104_102.28%)]'
      },
      {
        title: 'Web3 Course 102 - Intermediate',
        buttonText: t('common.get_start'),
        buttonLink: '/courses/102',
        bg: BG2,
        bgCls: 'bg-[linear-gradient(248.4deg,#040c79_-23.42%,#030616_77.3%)]'
      },
      {
        title: 'Web3 Course 103',
        buttonText: t('common.upcoming'),
        buttonLink: '/courses',
        bg: BG3,
        bgCls: 'bg-[linear-gradient(74deg,#010705_8.8%,#004938_124.15%)]'
      }
    ];
  }, [t]);

  return (
    <section className={cn('relative py-12 md:mb-14 lg:py-16', className)}>
      <div className="mx-auto flex max-w-screen-xl flex-col justify-center px-6 py-8 lg:px-8 2xl:max-w-screen-2xl">
        <m.div
          initial={{ opacity: 0, translateX: '-10%' }}
          whileInView={{ opacity: 1, translateX: '0)' }}
          transition={{
            type: 'spring',
            duration: 0.8
          }}
          className="max-w-xl"
        >
          <h2 className="font-Manrope text-2xl font-bold md:text-5xl md:leading-[72px]">
            {t('home.section.course.track')}
          </h2>

          <p className="mt-4 font-Manrope text-sm font-medium leading-6 md:mt-6 md:text-lg">
            {t('home.section.course.track.desc')}
          </p>
        </m.div>

        <div className="mt-10 grid grid-cols-1 gap-8 md:mt-28 md:grid-cols-3">
          {cards.map((card) => (
            <m.div
              key={card.title}
              initial={{ opacity: 0, translateY: '-10%' }}
              whileInView={{ opacity: 1, translateY: '0' }}
              transition={{
                type: 'spring',
                duration: 0.8
              }}
              className={cn(
                'relative max-h-[160px] w-full cursor-pointer overflow-hidden rounded-[10px] transition-transform 3xl:max-h-[160px]'
              )}
            >
              <img src={card.bg} className={cn(card.bgCls, 'w-full')} />
              <div className="group absolute inset-0 flex flex-col justify-center px-5 py-4 transition-all hover:bg-black/20 sm:px-[10px] sm:py-2 md:justify-between xl:px-5 xl:py-4 3xl:justify-between">
                <h2 className="line-clamp-2 text-lg font-semibold transition-all group-hover:text-white/90 sm:text-lg xl:text-[22px] xl:leading-10">
                  {card.title}
                </h2>
                <div
                  onClick={() => {
                    if (card.buttonLink) {
                      void navigate(card.buttonLink);
                    }
                  }}
                  className="mt-4 flex items-center gap-2 font-Manrope text-base font-medium text-[#50F5FF] outline-none transition-all focus:outline-none group-hover:text-[#50F5FF]/90 sm:mt-0 sm:mt-1 xl:mt-4 xl:text-lg xl:leading-9"
                >
                  <span className="pb-1 underline underline-offset-2">
                    {card.buttonText}
                  </span>
                  <ExternalLink />
                </div>
              </div>
            </m.div>
          ))}
        </div>
      </div>
    </section>
  );
}
