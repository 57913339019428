import { navigate } from 'gatsby';
import { useMount } from 'react-use';

import { isMoveCastle } from '../const';

export function useRouteGuardInMoveCastle() {
  useMount(() => {
    if (
      isMoveCastle &&
      !location.pathname.includes('/courses/move-on-sui') &&
      !location.pathname.includes('/authCallbackCenter')
    ) {
      void navigate('/courses/move-on-sui');
    }
  });
}
