import { useLocation } from '@reach/router';
import queryString from 'query-string';

// react-use的useSearchParams只能获取到react/router的路由参数，无法动态监听reach/router的路由参数
// 而gatsby的路由参数是在@reach/router的基础上做了一层封装，需要使用 @reach/router的useLocation来动态监听
export function useGatsbySearchParam(paramKey: string) {
  const { search } = useLocation();
  const parseSearch = queryString.parse(search);
  return parseSearch[paramKey] as string;
}
