export default {
  'common.get_start': 'Get Started',
  'common.upcoming': 'Upcoming',
  'common.button.start_learning': 'Start Learning',
  'common.button.keep_going': 'Keep Going',
  'common.button.review': 'Review',
  'common.free': 'Free',
  'common.lessons': 'lessons',
  'common.min': 'min',
  'common.no_results': 'no results'
};
