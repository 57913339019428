import { useEffect, useState } from 'react';
import { useDebounce, useMount, useWindowScroll } from 'react-use';

/**
 * 滚动位置恢复
 * @param storageKey 存储的key值
 * @param debounceTime 防抖时间，单位ms
 */
export function useScrollRestore(storageKey: string, debounceTime: number) {
  const [isMounted, setMounted] = useState(false);
  useMount(() => {
    setMounted(true);
  });
  const { y } = useWindowScroll();
  useDebounce(
    () => {
      localStorage.setItem(storageKey, scrollY.toString());
    },
    debounceTime,
    [y]
  );
  useEffect(() => {
    if (isMounted) {
      const scrollY = localStorage.getItem(storageKey);
      if (scrollY) {
        window.scrollTo(0, Number(scrollY));
      }
    }
  }, [isMounted, storageKey]);
}
