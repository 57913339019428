import cn from 'classnames';
import React from 'react';

import EmptyImg from '@/assets/images/common/empty.inline.svg';
import { useTranslation } from '@/i18n';
interface IEmptyProps {
  className?: string;
  tipClassName?: string;
  tip?: string;
}
export function Empty(props: IEmptyProps) {
  const { className, tip, tipClassName } = props;
  const { t } = useTranslation();
  return (
    <div className={cn(className, 'pt-[68px] sm:pt-[168px]')}>
      <EmptyImg className="mx-auto w-[250px] sm:w-[300px]" />
      <p
        className={cn(
          tipClassName,
          'mt-5 text-center leading-[1.5] text-[#50ECF9]'
        )}
      >
        {tip || t('common.no_results')}
      </p>
    </div>
  );
}
