/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import cn from 'classnames';
import { GITHUB_APP_CLIENT_ID } from 'gatsby-env-variables';
import React, { ReactNode, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useMount } from 'react-use';

import request, { logoutEmitter } from '@/services/request';
import { useAppStore } from '@/store/appStore';
import {
  getSearchParamsFromUrl,
  removeSearchParamsShallow
} from '@/utils/common';
import { ESearchParamKeys } from '@/utils/const';
import { useRouteGuardInMoveCastle, useUpdateProfile } from '@/utils/hooks';
import { removeTokenData, saveTokenData } from '@/utils/tokenUtils';

import { IFrameEditor } from '../IFrameEditor';
import { Footer } from './Footer';
import { Header } from './Header';

interface LayoutProps {
  [key: string]: unknown;
  className?: string;
  children: ReactNode;
}

export function Layout(props: LayoutProps) {
  const { className, children, ...rest } = props;
  const { setUserInfo, setLogining } = useAppStore();
  const { search } = useMemo(() => rest.location as Location, [rest.location]);
  const isInLessonDetailPage = useMemo(() => {
    if (
      search.includes(`?${ESearchParamKeys.SECTION_SEQUENCE}=`) ||
      search.includes(`?${ESearchParamKeys.LESSON_SEQUENCE}=`)
    )
      return true;
    return false;
  }, [search]);

  useRouteGuardInMoveCastle();

  const { update } = useUpdateProfile();

  useMount(() => {
    const [code] = getSearchParamsFromUrl(['code']);
    if (!code) return;
    async function login() {
      setLogining(true);
      try {
        const tokenData = await request('/api/v1/user/github/login', {
          data: { code, clientId: GITHUB_APP_CLIENT_ID },
          method: 'post'
        });
        saveTokenData(tokenData);
        update();
        removeSearchParamsShallow(['code']);
      } catch (e) {
        removeSearchParamsShallow(['code']);
        console.error(e);
        toast.error('Login failed!');
      }
      setLogining(false);
    }
    void login();
  });
  useMount(update);

  useEffect(() => {
    const dispose = logoutEmitter.event(() => {
      removeTokenData();
      setUserInfo(undefined);
    });
    return () => {
      dispose.dispose();
    };
  }, [setUserInfo]);

  return (
    <div className={cn(className)}>
      {!isInLessonDetailPage && <Header />}
      <main>
        {children}
        <IFrameEditor isInLessonDetailPage={isInLessonDetailPage} />
      </main>
      {!isInLessonDetailPage && <Footer />}
    </div>
  );
}
