import { BUILD_ENV } from 'gatsby-env-variables';

export enum EStorageKeys {
  'TOKEN_DATA' = 'token_data',
  'SHOW_EDITOR' = 'show_editor',
  'COURSE_DETAIL_PAGE_SCROLL_POSITION' = 'course_detail_scroll_position'
}

export enum ESearchParamKeys {
  'SECTION_SEQUENCE' = 'sectionSequence',
  'LESSON_SEQUENCE' = 'lessonSequence'
}

export const DEFAULT_LESSON_SEQUENCE = '1';

export const DEFAULT_SECTION_SEQUENCE = '1';

// chapter、section、lesson 的默认启始序号
export const DEFAULT_SEQUENCE = 1;

export const COURSE_DETAIL_PATH = '/courses';

export const COURSE_DEFAULT_COVER =
  'https://d1pbwflyd697fe.cloudfront.net/lesson_banner1.png';
export const UPCOMING_COURSE_DEFAULT_COVER =
  'https://d1pbwflyd697fe.cloudfront.net/lesson_banner3.png';

export const isMoveCastle = BUILD_ENV === 'movecastle';
// export const isMoveCastle = true;
