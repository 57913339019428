/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import {
  AccordionMultipleProps,
  AccordionSingleProps
} from '@radix-ui/react-accordion';
import cn from 'classnames';
import * as React from 'react';
import { useLocalStorage } from 'react-use';

import ChevronDown from '@/assets/images/courses/syllabus-dropdown.inline.svg';

const Accordion = AccordionPrimitive.Root;
// @ts-ignore
const SyllabusAccordion: React.ForwardRefExoticComponent<
  (AccordionSingleProps | AccordionMultipleProps) &
    React.RefAttributes<HTMLDivElement> & { storageKey: string }
> = (props: any) => {
  const { storageKey } = props;
  const [value, setValue] = useLocalStorage(storageKey, '');
  return (
    <AccordionPrimitive.Root
      {...props}
      value={value}
      onValueChange={setValue}
    />
  );
};

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item ref={ref} className={cn(className)} {...props} />
));
AccordionItem.displayName = 'AccordionItem';

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex items-center rounded bg-[#16202D] px-4 py-1 sm:px-12">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        'flex flex-1 items-center justify-start py-4 font-medium transition-all [&[data-state=open]>svg]:rotate-180',
        className
      )}
      {...props}
    >
      <ChevronDown className="mr-4 shrink-0 transition-transform duration-200" />
      {children}
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={cn(
      'overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down',
      className
    )}
    {...props}
  >
    {children}
  </AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  SyllabusAccordion
};
