// from https://github.com/zzarcon/react-circle/blob/master/src/circle.tsx
import cn from 'classnames';
import React, { CSSProperties } from 'react';

interface ICircleProgressProps {
  className?: string;
  progress: number;
  animate?: boolean;
  animationDuration?: string;
  showPercentage?: boolean;
  showPercentageSymbol?: boolean;
  progressColor?: string;
  bgColor?: string;
  textColor?: string;
  size?: string;
  lineWidth?: string;
  percentSpacing?: number;
  textStyle?: CSSProperties;
  roundedStroke?: boolean;
  responsive?: boolean;
  onAnimationEnd?: () => void;
}

const radius = 175;
const diameter = Math.round(Math.PI * radius * 2);
const getOffset = (val = 0) =>
  Math.round(((100 - Math.min(val, 100)) / 100) * diameter);

export function CircleProgress(props: ICircleProgressProps) {
  const {
    className,
    progress = 70,
    animate = true,
    animationDuration = '1s',
    showPercentage = true,
    showPercentageSymbol = true,
    progressColor = '#3E81FF',
    bgColor = '#3E81FF80',
    textColor = '#3E81FF',
    size = '28',
    lineWidth = '48',
    percentSpacing = 6,
    textStyle = { font: '7rem Poppins' },
    responsive = false,
    roundedStroke = false,
    onAnimationEnd
  } = props;
  const strokeDashoffset = getOffset(progress);
  const transition = animate
    ? `stroke-dashoffset ${animationDuration || ''} ease-out`
    : undefined;
  const strokeLinecap = roundedStroke ? 'round' : 'butt';
  const svgSize = responsive ? '100%' : size;

  return (
    <svg
      width={svgSize}
      height={svgSize}
      viewBox="-25 -25 400 400"
      className={cn(className)}
    >
      <circle
        stroke={bgColor}
        cx="175"
        cy="175"
        r="175"
        strokeWidth={lineWidth}
        fill="none"
      />
      <circle
        stroke={progressColor}
        transform="rotate(-90 175 175)"
        cx="175"
        cy="175"
        r="175"
        strokeDasharray="1100"
        strokeWidth={lineWidth}
        strokeDashoffset="1100"
        strokeLinecap={strokeLinecap}
        fill="none"
        style={{ strokeDashoffset, transition }}
        onTransitionEnd={onAnimationEnd}
      />
      {showPercentage && (
        <text
          style={textStyle}
          fill={textColor}
          x={radius}
          y={radius}
          textAnchor="middle"
          dominantBaseline="central"
        >
          <tspan dx={percentSpacing}>{progress}</tspan>
          {showPercentageSymbol && <tspan dx={percentSpacing}>%</tspan>}
        </text>
      )}
    </svg>
  );
}
