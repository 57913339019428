import cn from 'classnames';
import { Link } from 'gatsby';
import React from 'react';

import Discord from '@/assets/images/profile/discord.inline.svg';
import Github from '@/assets/images/profile/github.inline.svg';
import Settings from '@/assets/images/profile/settings.inline.svg';
import Twitter from '@/assets/images/profile/twitter.inline.svg';
import { Avatar } from '@/components';
import { useAppStore } from '@/store/appStore';

export function ProfileHeader() {
  const { userInfo } = useAppStore();
  return (
    <section>
      <div
        className="h-[173px] w-full bg-[#1A2842] bg-cover bg-center"
        style={
          userInfo?.banner
            ? {
                backgroundImage: `url(${userInfo.banner})`
              }
            : {}
        }
      ></div>
      <div className="page-layout !relative !min-h-[0px]">
        <div className="absolute -top-[70px] h-[100px] w-[100px] rounded-full bg-[#0a111a] p-1">
          <Avatar size="big" />
        </div>
        {/* <Link to={ssoProfilePageHref} target="_blank">
          <Settings className="absolute right-4" />
        </Link> */}
        <div className="mb-4 mt-5 pt-10">
          <div className="flex flex-wrap items-center gap-x-[14px] gap-y-1">
            <span className="text-2xl font-medium leading-[1.5]">
              {userInfo?.username}
            </span>
            <ul className="flex items-center gap-x-2">
              {userInfo?.githubInfo?.username && (
                <Link
                  to={`https://github.com/${userInfo.githubInfo.username}`}
                  target="_blank"
                >
                  <Github />
                </Link>
              )}
              {userInfo?.discordInfo?.userId && (
                <Link
                  to={`https://discordapp.com/users/${userInfo.discordInfo.userId.replace(
                    'DISCORD_',
                    ''
                  )}`}
                  target="_blank"
                >
                  <Discord />
                </Link>
              )}
              {userInfo?.twitterInfo?.username && (
                <Link
                  to={`https://twitter.com/${userInfo.twitterInfo.username}`}
                  target="_blank"
                >
                  <Twitter />
                </Link>
              )}
            </ul>
          </div>
          <p className="text-sm leading-[18px] text-[#97A2B8]">
            {userInfo?.email}
          </p>
        </div>
        <p
          className={cn(
            'whitespace-pre-wrap text-lg',
            userInfo?.description ? 'mb-[35px]' : 'mb-[15px]'
          )}
        >
          {userInfo?.description}
        </p>
      </div>
    </section>
  );
}
