import cn from 'classnames';
import React from 'react';
import { useLocalStorage } from 'react-use';

import ArrowIcon from '@/assets/images/profile/arrow.inline.svg';
import { useTranslation } from '@/i18n';

import { ProfileContentType } from './ProfileContent';

interface IProfileAccordionProps {
  contentType?: ProfileContentType;
  setContentType: (type: ProfileContentType) => void;
}
export function ProfileAccordion(props: IProfileAccordionProps) {
  const { contentType, setContentType } = props;
  const [showSub, setShowSub] = useLocalStorage('profile-show-sub', true);
  const { t } = useTranslation();
  return (
    <div className="py-[30px]">
      <div
        onClick={() => {
          setShowSub(!showSub);
        }}
        className="flex cursor-pointer flex-row items-center gap-x-2 pb-[10px]"
      >
        <ArrowIcon
          className={cn(
            'shrink-0 duration-200',
            showSub ? 'rotate-0' : 'rotate-180'
          )}
        />
        <span className="line-clamp-1 text-xl font-medium leading-[30px]">
          Education
        </span>
      </div>
      <ul
        className={cn(
          'flex flex-col overflow-hidden duration-200',
          showSub ? 'h-[92px]' : 'h-[0px]   '
        )}
      >
        <li
          onClick={() => {
            setContentType('edu_history');
          }}
          className={cn(
            'mb-1 ml-[20px] h-11 w-[calc(100%_-_20px)] cursor-pointer rounded px-4 leading-[44px] hover:bg-[#1A2842]',
            contentType === 'edu_history' && 'bg-[#1A2842]'
          )}
        >
          {t('profile.aside.history')}
        </li>
        <li className="ml-[20px] flex h-11 w-[calc(100%_-_20px)] items-center gap-x-2 rounded px-4 leading-[44px] text-[#97A2B8]">
          <span>{t('profile.aside.awards')}</span>
          <div className="h-5 rounded bg-[#4F596C] p-1 text-xs font-medium leading-[12px]">
            {t('common.upcoming')?.toLowerCase()}
          </div>
        </li>
      </ul>
    </div>
  );
}
