import { useCallback } from 'react';

import request from '@/services/request';
import { useAppStore } from '@/store/appStore';

import { getTokenData } from '../tokenUtils';

export function useUpdateProfile() {
  const { setUserInfo } = useAppStore();
  const update = useCallback(() => {
    if (getTokenData()) {
      request('/api/v1/user/info', { method: 'get' })
        .then(setUserInfo)
        .catch(console.error);
    }
  }, [setUserInfo]);
  return { update };
}
