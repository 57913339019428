import cn from 'classnames';
import { m } from 'framer-motion';
import { Link, navigate } from 'gatsby';
import React from 'react';
import { useLocation, useWindowScroll } from 'react-use';

import EduLogo from '@/assets/images/edu-logo.inline.svg';
import SuiLogo from '@/assets/images/sui.inline.svg';
import { useTranslation } from '@/i18n';
import { isMoveCastle } from '@/utils/const';

import { Account } from './Account';
import { LanguageSwitch } from './LanguageSwitch';
import { menus, MobileMenuNav } from './MobileMenuNav';

interface HeaderProps {
  className?: string;
}

export function Header(props: HeaderProps) {
  const { className } = props;

  const { t } = useTranslation();
  const { y } = useWindowScroll();
  const { pathname } = useLocation();

  return (
    <header
      aria-label="ChainIDE Education Header"
      className={cn('sticky inset-x-0 top-0 z-50', 'bg-[#131C28]', className)}
    >
      <div className="mx-auto flex h-16 max-w-screen-xl items-center justify-between px-4 sm:gap-4 sm:px-6 lg:h-[72px] lg:gap-8 lg:px-8 2xl:max-w-screen-2xl">
        <div
          className={cn(
            'flex items-center text-white',
            !isMoveCastle && 'cursor-pointer'
          )}
          onClick={() => {
            if (!isMoveCastle) {
              void navigate('/');
            }
          }}
        >
          <span className="sr-only">{t('header.logo.label')}</span>
          <EduLogo
            className={cn(
              'sm:w-[250px]',
              isMoveCastle ? 'w-[160px]' : 'w-[200px]'
            )}
          />
          {isMoveCastle && (
            <SuiLogo className="ml-3 mt-1 w-[50px] sm:w-[87px]" />
          )}
        </div>

        <div className="md:flex md:items-center md:gap-8">
          <nav aria-label="ChainIDE Education Nav" className="hidden md:block">
            <ul className="flex items-center gap-6 text-lg leading-[18px]">
              {menus.map((menu) => (
                <m.li
                  key={menu.link}
                  initial={{ color: '#fff' }}
                  whileHover={{ color: '#256BEF' }}
                  transition={{
                    type: 'spring',
                    duration: 1
                  }}
                  className="rounded-md px-[6px] py-2"
                >
                  <Link
                    to={menu.link}
                    className={cn(
                      pathname?.includes(menu.link) &&
                        menu.link !== '' &&
                        'text-primaryColor duration-200'
                    )}
                  >
                    {t(menu.label)}
                  </Link>
                </m.li>
              ))}
            </ul>
          </nav>

          <div className="flex items-center gap-4">
            <div className="flex gap-6">
              <LanguageSwitch />
              <Account />
            </div>

            <MobileMenuNav />
          </div>
        </div>
      </div>
    </header>
  );
}
