/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import cn from 'classnames';
import { AnimatePresence, m } from 'framer-motion';
import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';

import ArrowDown from '@/assets/images/common/arrow-down.inline.svg';
import TickMark from '@/assets/images/common/tick-mark.inline.svg';
import { switchLanguage } from '@/i18n';
import { COURSE_DETAIL_PATH, isMoveCastle } from '@/utils/const';

interface MobileMenuNavProps {
  className?: string;
}

const Path = (props: any) => (
  <m.path
    fill="transparent"
    strokeWidth="3"
    stroke="#fff"
    strokeLinecap="round"
    {...props}
  />
);

export const menus = isMoveCastle
  ? []
  : [
      {
        label: 'header.nav.courses',
        link: `${COURSE_DETAIL_PATH}`
      },
      {
        label: 'header.nav.contact',
        link: ''
      }
    ];

export function MobileMenuNav(props: MobileMenuNavProps) {
  const { className } = props;

  const { t } = useTranslation();

  const [isOpen, toggleOpen] = useToggle(false);

  const onClickMenu = (link: string) => {
    void navigate(link);
    toggleOpen(false);
  };

  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const { i18n } = useTranslation();

  const languageOption: Array<{ label: string; language: 'en-US' | 'zh' }> = [
    {
      label: t('language.en'),
      language: 'en-US'
    }
    // {
    //   label: t('language.zh'),
    //   language: 'zh'
    // }
  ];

  const handleSwitchLanguage = (language: 'en-US' | 'zh') => {
    switchLanguage(language);
  };

  useEffect(() => {
    const bodyEle = document.getElementsByTagName('body');
    if (bodyEle[0]) {
      if (isOpen) {
        bodyEle[0].style.height = '100vh';
        bodyEle[0].style.overflowY = 'hidden';
      } else {
        bodyEle[0].style.height = 'auto';
        bodyEle[0].style.overflowY = 'auto';
      }
    }
  }, [isOpen]);

  return (
    <m.div
      initial={false}
      layout={true}
      animate={isOpen ? 'open' : 'closed'}
      className={cn(
        'relative flex items-center justify-center md:hidden',
        className
      )}
    >
      <button
        onClick={() => toggleOpen()}
        className="z-50 border-none bg-transparent pt-1 outline-none"
      >
        <span className="sr-only"> {t('header.menu.label')}</span>
        <svg width="23" height="23" viewBox="0 0 23 23">
          <Path
            variants={{
              closed: { d: 'M 2 2.5 L 20 2.5' },
              open: { d: 'M 3 16.5 L 17 2.5' }
            }}
          />
          <Path
            d="M 2 9.423 L 20 9.423"
            variants={{
              closed: { opacity: 1 },
              open: { opacity: 0 }
            }}
            transition={{ duration: 0.1 }}
          />
          <Path
            variants={{
              closed: { d: 'M 2 16.346 L 20 16.346' },
              open: { d: 'M 3 2.5 L 17 16.346' }
            }}
          />
        </svg>
      </button>
      <AnimatePresence>
        {isOpen && (
          <m.div
            initial={{
              opacity: 0
            }}
            animate={
              {
                opacity: 1,
                transition: {
                  type: 'spring',
                  beforeChildren: 0.25
                }
              } as any
            }
            exit={
              {
                opacity: 0,
                transition: {
                  type: 'spring',
                  afterChildren: 0.25
                }
              } as any
            }
            className="fixed inset-0 z-40 bg-[rgba(0,0,0,0.6)]"
          >
            <m.div
              initial={{
                opacity: 0,
                translateX: '100%'
              }}
              animate={{
                opacity: 1,
                translateX: 0,
                transition: {
                  type: 'linear',
                  duration: 0.8
                }
              }}
              exit={{
                opacity: 0,
                translateX: '100%',
                transition: {
                  type: 'linear',
                  duration: 0.8
                }
              }}
              className={cn(
                'fixed inset-0 left-[calc(100vw_-_300px)] z-50 w-[300px] bg-[#071526]'
              )}
            >
              <ul className="absolute top-[100px] z-50 w-[300px]">
                {menus.map((menu, index) => (
                  <m.li
                    key={menu.link}
                    initial={{
                      opacity: 0,
                      translateX: '100%'
                    }}
                    animate={{
                      opacity: 1,
                      translateX: 0
                    }}
                    transition={{
                      type: 'linear',
                      duration: 0.8,
                      delay: 0.1 + 0.1 * index
                    }}
                    onClick={() => onClickMenu(menu.link)}
                    className="py-3 pl-8 hover:bg-[#1D314F]"
                  >
                    <span className="text-lg">{t(menu.label)}</span>
                  </m.li>
                ))}
                <m.div
                  initial={{
                    opacity: 0,
                    translateX: '100%'
                  }}
                  animate={{
                    opacity: 1,
                    translateX: 0
                  }}
                  transition={{
                    type: 'linear',
                    duration: 0.8,
                    delay: 0.1 + 0.1 * (menus.length + 1)
                  }}
                >
                  <m.nav
                    initial={false}
                    animate={isLanguageOpen ? 'expanded' : 'collapsed'}
                  >
                    <div
                      onClick={() => setIsLanguageOpen(!isLanguageOpen)}
                      className="flex items-center justify-between px-8 py-3 hover:bg-[#1D314F]"
                    >
                      <span className="text-lg">{t('languages')}</span>
                      <m.div
                        variants={{
                          expanded: { rotate: 0 },
                          collapsed: { rotate: -90 }
                        }}
                        transition={{ duration: 0.2 }}
                        style={{ originY: 0.55 }}
                      >
                        <ArrowDown />
                      </m.div>
                    </div>
                    <m.ul
                      variants={{
                        expanded: {
                          clipPath: 'inset(0% 0% 0% 0% round 0px)',
                          transition: {
                            type: 'spring',
                            bounce: 0,
                            duration: 0.5,
                            delayChildren: 0.15,
                            staggerChildren: 0.05
                          }
                        },
                        collapsed: {
                          clipPath: 'inset(10% 50% 90% 50% round 10px)',
                          transition: {
                            type: 'spring',
                            bounce: 0,
                            duration: 0.3
                          }
                        }
                      }}
                    >
                      {languageOption.map((item) => (
                        <m.li
                          key={item.language}
                          role="button"
                          variants={{
                            expanded: {
                              opacity: 1,
                              y: 0,
                              transition: {
                                type: 'spring',
                                stiffness: 300,
                                damping: 24
                              }
                            },
                            collapsed: {
                              opacity: 0,
                              y: 20,
                              transition: { duration: 0.2 }
                            }
                          }}
                          onClick={() => handleSwitchLanguage(item.language)}
                          className="mt-[1px] flex items-center justify-between bg-[#112239] py-3 pl-12 pr-8 focus:bg-[#1D314F]"
                        >
                          <span className="text-lg">{item.label}</span>
                          {i18n.language === item.language && <TickMark />}
                        </m.li>
                      ))}
                    </m.ul>
                  </m.nav>
                </m.div>
              </ul>
            </m.div>
          </m.div>
        )}
      </AnimatePresence>
    </m.div>
  );
}
