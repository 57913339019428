/* eslint-disable promise/no-promise-in-callback */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import './mock';

import { Emitter } from '@white-matrix/event-emitter';
import axios, { AxiosRequestConfig } from 'axios';
import { BASE_URL } from 'gatsby-env-variables';
import { cloneDeep } from 'lodash';
import { toast } from 'react-toastify';

import { t } from '@/i18n';
import { checkIsInLessonDetailPage } from '@/utils/common';
import { getTokenData, saveTokenData } from '@/utils/tokenUtils';

export const logoutEmitter = new Emitter<void>();
const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 30000
});

let reqQueue = [] as any[]; // 需要token才可请求的请求列表
let isRefreshingToken = false; // 是否已经在刷新token
instance.interceptors.response.use(
  (response) => {
    // data解构，是否需要
    if (response.data) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return response.data;
    }
    return response;
  },
  (error) => {
    const config = error.config as AxiosRequestConfig;
    const status = error?.response?.status;
    if ([401, 403].includes(status)) {
      // access token失效，需要请求sso刷新token
      if (!isRefreshingToken) {
        console.log('refresh token: start');
        isRefreshingToken = true;
        // 执行 refrehs token
        instance
          .request({
            url: '/api/v1/user/refresh',
            method: 'post',
            data: {
              refreshToken: getTokenData()?.refreshToken || ''
            }
          })
          .then((v) => {
            saveTokenData(v);
            for (const req of reqQueue) {
              req();
            }
          })
          .catch(() => {
            // means refresh token expired
            console.log('refresh token expired');
            if (checkIsInLessonDetailPage()) {
              toast.error(t('tip.login_again'));
            }
            logoutEmitter.fire();
          })
          .finally(() => {
            reqQueue = [];
            isRefreshingToken = false;
          });
      }

      return new Promise((resolve) => {
        // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
        reqQueue.push(() => {
          if (config.headers) {
            config.headers.Authorization = `Bearer ${
              getTokenData()?.accessToken as string
            }`;
          }
          resolve(instance(cloneDeep(config)));
        });
      });
    } else {
      throw error;
    }
  }
);

const tokenExcludePathArr = [
  '/api/v1/user/github/login',
  '/api/v1/user/refresh'
];
instance.interceptors.request.use((config) => {
  // get access token from local storage and set to config.header.Authorization
  const accessToken = getTokenData()?.accessToken as string;
  if (accessToken && !tokenExcludePathArr.includes(config.url || '')) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const request = async <T = any>(
  url: string,
  options: AxiosRequestConfig & { requestType?: 'json' | 'form' } = {}
) => {
  // 兼容from data文件上传的情况
  const { requestType, ...rest } = options;
  if (requestType === 'form') {
    return await instance.request<T, T>({
      url,
      ...rest,
      headers: {
        ...(rest.headers || {}),
        'Content-Type': 'multipart/form-data'
      }
    });
  } else {
    return await instance.request<T, T>({
      url,
      ...rest
    });
  }
};

export default request;
