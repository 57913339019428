export default {
  'course.property.attr': 'Course Attributes:',
  'course.property.skills': 'Skills You’ll Learn:',
  'course.property.progress': 'Learning progress:',

  'course.card.target.title': 'Target Audience',
  'course.card.includes.title': 'This Course Includes',

  'course.syllabus.title': 'Course Syllabus'
};
