/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import cn from 'classnames';
import { m } from 'framer-motion';
import React from 'react';
import { useWindowSize } from 'react-use';

import basecamp1 from '@/assets/images/home/basecamp/basecamp1.png';
import basecamp2 from '@/assets/images/home/basecamp/basecamp2.png';
import basecamp3 from '@/assets/images/home/basecamp/basecamp3.png';
import basecamp4 from '@/assets/images/home/basecamp/basecamp4.png';
import basecamp5 from '@/assets/images/home/basecamp/basecamp5.png';
import basecamp6 from '@/assets/images/home/basecamp/basecamp6.png';
import basecamp7 from '@/assets/images/home/basecamp/basecamp7.png';
import basecamp8 from '@/assets/images/home/basecamp/basecamp8.png';
import { useTranslation } from '@/i18n';

interface BasecampProps {
  className?: string;
}

const basecampList = [
  {
    type: 'smallCard',
    cards: [
      {
        type: 'text',
        label: '30,000+',
        text: 'home.section.bootcamp.developers',
        background: '#1C1D28',
        color: '#50F5FF',
        hoverBg: 'rgba(255,255,255,.05)',
        link: 'https://chainide.com/events/developer-summit'
      },
      {
        type: 'image',
        link: 'https://twitter.com/ChainIDE/status/1638097389021171712',
        imageAlt:
          '@ChainIDE We had an awesome meetup with @flow_blockchain and @ 1',
        imageSrc: basecamp1
      }
    ]
  },
  {
    type: 'fullImage',
    width: '599px',
    height: '414px',
    link: 'https://twitter.com/ChainIDE/status/1638097389021171712',
    imageAlt: '@ChainIDE Let’s keep on building together',
    imageSrc: basecamp2
  },
  {
    type: 'smallCard',
    cards: [
      {
        type: 'image',
        link: 'https://twitter.com/ChainIDE/status/1635167160644534272',
        imageAlt:
          '@ChainIDE ETH Dubai Meetups Its a memorable journey to catch up 1',
        imageSrc: basecamp3
      },
      {
        type: 'text',
        label: '10,000+',
        text: 'home.section.bootcamp.projects',
        background: '#1C1D28',
        color: '#357BFF',
        hoverBg: 'rgba(255,255,255,.05)',
        link: 'https://chainide.com/events/'
      }
    ]
  },
  {
    type: 'fullImage',
    width: '299px',
    height: '414px',
    link: 'https://twitter.com/ChainIDE/status/1635167160644534272',
    imageAlt:
      '@ChainIDE Johnny Zhao, the Marketing Director at ChainIDE, was ab',
    imageSrc: basecamp4
  },
  {
    type: 'smallCard',
    cards: [
      {
        type: 'text',
        label: '200k+',
        text: 'home.section.bootcamp.participants',
        background: '#50F5FF',
        color: '#1C1D28',
        hoverBg: 'rgba(0,0,0,0.15)',
        link: 'https://chainide.com/events/bootcamp'
      },
      {
        type: 'image',
        link: 'https://twitter.com/ChainIDE/status/1647787497093763073',
        imageAlt:
          '@ChainIDE GM Builders What a fruitful week for us to connect wi 1',
        imageSrc: basecamp5
      }
    ]
  },
  {
    type: 'fullImage',
    width: '599px',
    height: '414px',
    link: 'https://twitter.com/ChainIDE/status/1647787497093763073',
    imageAlt:
      '@ChainIDE We are definitely having fun in Web3 at the Hong Kong W',
    imageSrc: basecamp6
  },
  {
    type: 'smallCard',
    cards: [
      {
        type: 'image',
        link: 'https://twitter.com/ChainIDE/status/1646068801124061184',
        imageAlt:
          '@ChainIDE Had an amazing time on the Web3 Mass Adoption panel at 1',
        imageSrc: basecamp7
      },
      {
        type: 'text',
        label: '8.5M+',
        text: 'home.section.bootcamp.compiledContract',
        background: '#357BFF',
        color: '#1C1D28',
        hoverBg: 'rgba(0,0,0,0.15)',
        link: 'https://chainide.com/events/workshop-seminar'
      }
    ]
  },
  {
    type: 'fullImage',
    link: 'https://twitter.com/ChainIDE/status/1648162338716135424',
    width: '299px',
    height: '414px',
    imageAlt: '@ChainIDE Were thrilled to announce that our recent Web3 Build',
    imageSrc: basecamp8
  }
];

export function Basecamp(props: BasecampProps) {
  const { className } = props;

  const { t } = useTranslation();
  const { width } = useWindowSize();

  const maxTranslateX = width - 3184;
  const isBigScreen = width > 1920;

  return (
    <section className={cn('mt-16 3xl:my-32', className)}>
      <div className="mx-auto max-w-screen-xl px-6 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16 2xl:max-w-screen-2xl">
        <div className="max-w-xl text-left">
          <h2 className="font-Manrope text-xl font-bold capitalize leading-10 md:text-[56px] md:leading-[80px]">
            {t('home.section.bootcamp')}
          </h2>
        </div>
      </div>
      <m.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={
          {
            type: 'spring',
            duration: 0.8,
            beforeChildren: 0.15
          } as any
        }
      >
        <m.div
          layout={true}
          animate={{
            translateX: [0, maxTranslateX, 0]
          }}
          transition={{
            ease: 'linear',
            duration: isBigScreen ? 45 : 60,
            repeat: Infinity,
            repeatDelay: 0.5
          }}
          className="inline-flex gap-6 pr-6"
        >
          {basecampList.map((basecamp, index) => {
            if (basecamp.type === 'smallCard') {
              return (
                <div
                  key={`${basecamp.type}-${index}`}
                  className="grid-col-1 grid h-[414px] grid-flow-col grid-rows-2 gap-6"
                >
                  {basecamp.cards?.map((card) => {
                    if (card.type === 'text') {
                      return (
                        <a
                          key={card.text}
                          href={card.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={cn(
                            'group relative flex w-[299px] flex-col items-center justify-center gap-3 overflow-hidden rounded-lg hover:shadow-[0px_6px_20px_rgba(0,0,0,0.7)]'
                          )}
                          style={{
                            background: card.background,
                            color: card.color
                          }}
                        >
                          <div
                            className={cn(
                              'absolute inset-0 hidden transition-all group-hover:block'
                            )}
                            style={{ background: card.hoverBg }}
                          />
                          <div className="font-Manrope text-[44px] font-bold leading-[80px]">
                            {card.label}
                          </div>
                          <div className="font-Manrope text-base capitalize leading-7">
                            {t(card.text || '')}
                          </div>
                        </a>
                      );
                    } else {
                      return (
                        <a
                          key={card.link}
                          href={card.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="group relative overflow-hidden rounded-lg bg-[#1C1D28]"
                        >
                          <img
                            src={card.imageSrc}
                            alt={card.imageAlt}
                            className="h-full w-full"
                          />
                          <div className="absolute inset-0 bg-black opacity-0 transition-all group-hover:opacity-30" />
                        </a>
                      );
                    }
                  })}
                </div>
              );
            } else {
              return (
                <a
                  href={basecamp.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={`${basecamp.type}-${index}`}
                  className={cn(
                    'group relative block overflow-hidden rounded-lg bg-[#1C1D28] hover:bg-black'
                  )}
                  style={{ width: basecamp.width, height: basecamp.height }}
                >
                  <img
                    src={basecamp.imageSrc}
                    alt={basecamp.imageAlt}
                    className="h-full w-full"
                  />
                  <div className="absolute inset-0 bg-black opacity-0 transition-all group-hover:opacity-30" />
                </a>
              );
            }
          })}
        </m.div>
      </m.div>
    </section>
  );
}
