import cn from 'classnames';
import React from 'react';

import ClockIcon from '@/assets/images/courses/clock.inline.svg';
import CodeIcon from '@/assets/images/courses/code.inline.svg';
import defaultPeopleImg from '@/assets/images/courses/default-people.png';
import PeopleIcon from '@/assets/images/courses/people.inline.svg';
import StarIcon from '@/assets/images/courses/star.inline.svg';
import { useTranslation } from '@/i18n';

import { CardLayout } from '../../../common/Layout/CardLayout';
interface ISectionCourseAudienceAndIncludesProps {
  className?: string;
  targetAudience?: string[];
  courseIncludes?: API.CourseIncludesDTO;
  studentCount?: number;
}
export function SectionCourseAudienceAndIncludes(
  props: ISectionCourseAudienceAndIncludesProps
) {
  const {
    className,
    targetAudience = [],
    courseIncludes = {},
    studentCount = 0
  } = props;
  const { t } = useTranslation();
  return (
    <section
      className={cn(
        className,
        'mb-10 mt-[60px] flex flex-wrap justify-between gap-y-6'
      )}
    >
      <CardLayout
        title={t('course.card.target.title')}
        className="md:w-[47.5%]"
      >
        <div className="flex-1 rounded-lg bg-[#16202D] p-5">
          <ul className="flex flex-col gap-y-[14px]">
            {targetAudience.map((item, index) => (
              <li key={item} className="flex items-start justify-start">
                <span
                  className={cn(
                    'mr-[10px] w-5 shrink-0 font-black text-primaryColor',
                    index === 0 && 'ml-[2px]'
                  )}
                >
                  {index + 1}.
                </span>
                <span className="text-[#97A2B8]">{item}</span>
              </li>
            ))}
          </ul>
        </div>
      </CardLayout>
      <CardLayout
        title={t('course.card.includes.title')}
        className="md:w-[47.5%]"
      >
        <div className="flex-1 rounded-lg bg-[#16202D] p-5">
          <ul className="flex flex-col gap-y-[14px]">
            <li className="flex items-start justify-start gap-x-[14px]">
              <ClockIcon />
              <span className="w-[calc(100%_-_30px)] text-[#97A2B8]">
                {courseIncludes?.time}
              </span>
            </li>
            <li className="flex items-start justify-start gap-x-[14px]">
              <CodeIcon />
              <span className="w-[calc(100%_-_30px)] text-[#97A2B8]">
                {courseIncludes?.code}
              </span>
            </li>
            <li className="flex items-start justify-start gap-x-[14px]">
              <StarIcon />
              <span className="w-[calc(100%_-_30px)] text-[#97A2B8]">
                {courseIncludes?.award}
              </span>
            </li>
            <li className="flex items-center justify-start gap-x-[14px]">
              <PeopleIcon />
              <div className="flex items-center gap-x-2">
                <img src={defaultPeopleImg} alt="Course Track Bg" width={62} />
                <span className="flex items-center justify-center rounded-2xl bg-primaryColor px-2 py-1 text-xs text-white">
                  +{studentCount}
                </span>
              </div>
            </li>
          </ul>
        </div>
      </CardLayout>
    </section>
  );
}
