// eslint-disable-next-line import/no-unresolved
import 'swiper/css';

import cn from 'classnames';
import { m } from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import ArrowLink from '@/assets/images/common/arrow-link.inline.svg';
import { PrimaryButton } from '@/components/common/PrimaryButton';
import { useTranslation } from '@/i18n';
import { useSize } from '@/utils/hooks';

interface SectionTutorialCategoriesProps {
  className?: string;
}

export function SectionTutorialCategories(
  props: SectionTutorialCategoriesProps
) {
  const { className } = props;

  const { t } = useTranslation();

  const TutorialCategories = [
    {
      coverImg: (
        <StaticImage
          src="../../../../assets/images/home/tutorial-category-1.png"
          alt="NFT Application Cover"
          layout="fullWidth"
          objectFit="contain"
        />
      ),
      title: t('home.section.tutorial.category1.title'),
      description: t('home.section.tutorial.category1.desc'),
      link: '/'
    },
    {
      coverImg: (
        <StaticImage
          src="../../../../assets/images/home/tutorial-category-2.png"
          alt="Decentralized Storage Cover"
          layout="fullWidth"
          objectFit="contain"
        />
      ),
      title: t('home.section.tutorial.category2.title'),
      description: t('home.section.tutorial.category2.desc'),
      link: '/'
    },
    {
      coverImg: (
        <StaticImage
          src="../../../../assets/images/home/tutorial-category-3.png"
          alt="Defi Application Cover"
          layout="fullWidth"
          objectFit="contain"
        />
      ),
      title: t('home.section.tutorial.category3.title'),
      description: t('home.section.tutorial.category3.desc'),
      link: '/'
    },
    {
      coverImg: (
        <StaticImage
          src="../../../../assets/images/home/tutorial-category-4.png"
          alt="Decentralized identity Cover"
          layout="fullWidth"
          objectFit="contain"
        />
      ),
      title: t('home.section.tutorial.category4.title'),
      description: t('home.section.tutorial.category4.desc'),
      link: '/'
    }
  ];

  const [slidesPerView, setSlidesPerView] = useState(4);
  const { isMobile, isMD, isLG } = useSize(640);
  const onResize = () => {
    if (isMobile) {
      setSlidesPerView(1);
    } else if (isMD) {
      setSlidesPerView(2);
    } else if (isLG) {
      setSlidesPerView(3);
    } else {
      setSlidesPerView(4);
    }
  };

  const [isPrevEnded, setIsPrevEnded] = useState(true);
  const [isNextEnded, setIsNextEnded] = useState(false);

  return (
    <section className={cn('relative mb-6 mt-8 md:mb-12', className)}>
      <div className="mx-auto max-w-screen-xl px-6 py-8 sm:py-12 lg:px-8 lg:py-16 2xl:max-w-screen-2xl">
        <div className="max-w-7xl items-end justify-between sm:flex sm:pr-6 lg:pr-8">
          <h2 className="font-Manrope text-2xl font-bold leading-9 lg:text-[48px] lg:leading-[72px]">
            {t('home.section.tutorial.categories')}
          </h2>
        </div>
        <div className="relative mt-8 hidden md:mt-12 md:block">
          <div className="absolute -top-12 flex gap-4 lg:right-0">
            <button
              className={cn(
                'prev rounded-full bg-[#44598F] p-[6px]',
                !isPrevEnded && 'hover:bg-primaryColor'
              )}
            >
              <span className="sr-only">Previous Slide</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-5 w-5 rtl:rotate-180"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            </button>

            <button
              className={cn(
                'next rounded-full bg-[#44598F] p-[6px]',
                !isNextEnded && 'hover:bg-primaryColor'
              )}
            >
              <span className="sr-only">Next Slide</span>
              <svg
                className="h-5 w-5 rtl:rotate-180"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 5l7 7-7 7"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
              </svg>
            </button>
          </div>
          <Swiper
            slidesPerView={slidesPerView}
            spaceBetween={24}
            modules={[Navigation]}
            navigation={{
              prevEl: '.prev',
              nextEl: '.next',
              enabled: true
            }}
            onResize={onResize}
            onSlideChange={(e) => {
              setIsPrevEnded(e.isBeginning);
              setIsNextEnded(e.isEnd);
            }}
          >
            {TutorialCategories.map((tutorial, index) => (
              <SwiperSlide key={tutorial.title}>
                <m.div
                  initial={{
                    opacity: 0,
                    transform: 'translate3d(-10%, -10%, 0)'
                  }}
                  whileInView={{
                    opacity: 1,
                    transform: 'translate3d(0, 0, 0)'
                  }}
                  transition={{
                    type: 'spring',
                    duration: 0.8,
                    delay: 0.5 * index + 0.6
                  }}
                  viewport={{ once: true }}
                  className="group flex origin-top-left cursor-pointer flex-col overflow-hidden rounded-lg border border-[#15254E] bg-[#15254E] transition-all duration-500 ease-in-out hover:border-[#5C667A]"
                >
                  <div className="relative overflow-hidden pb-[60%]">
                    <div className="absolute inset-0 overflow-hidden transition-all duration-500  ease-in-out group-hover:scale-[1.12]">
                      {tutorial.coverImg}
                    </div>
                  </div>
                  <div className="flex flex-col justify-center gap-1 bg-[#0F1320] px-4 py-6 3xl:py-8">
                    <h2 className="line-clamp-1 text-xl font-semibold leading-10">
                      {tutorial.title}
                    </h2>
                    <p
                      title={tutorial.description}
                      className="line-clamp-3 text-sm text-[#5B6584]"
                    >
                      {tutorial.description}
                    </p>
                    <PrimaryButton className="mt-3 inline-block w-32 px-2 py-1">
                      <span className="mr-1 text-sm">
                        {t('home.section.tutorial.category.card.buttonText')}
                      </span>
                      <ArrowLink className="mt-[2px]" />
                    </PrimaryButton>
                  </div>
                </m.div>
              </SwiperSlide>
            ))}
            {TutorialCategories.map((tutorial) => (
              <SwiperSlide key={tutorial.title}>
                <div className="group flex origin-top-left cursor-pointer flex-col overflow-hidden rounded-lg border border-[#15254E] bg-[#15254E] transition-all duration-500 ease-in-out hover:border-[#5C667A]">
                  <div className="relative overflow-hidden pb-[60%]">
                    <div className="absolute inset-0 overflow-hidden transition-all duration-500  ease-in-out group-hover:scale-[1.12]">
                      {tutorial.coverImg}
                    </div>
                  </div>
                  <div className="flex flex-col justify-center gap-1 bg-[#0F1320] px-4 py-6 3xl:py-8">
                    <h2 className="line-clamp-1 text-xl font-semibold leading-10">
                      {tutorial.title}
                    </h2>
                    <p
                      title={tutorial.description}
                      className="line-clamp-3 text-sm text-[#5B6584]"
                    >
                      {tutorial.description}
                    </p>
                    <PrimaryButton className="mt-3 inline-block w-32 px-2 py-1">
                      <span className="mr-1 text-sm">
                        {t('home.section.tutorial.category.card.buttonText')}
                      </span>
                      <ArrowLink className="mt-[2px]" />
                    </PrimaryButton>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="mt-8 flex flex-col gap-4 md:hidden">
          {TutorialCategories.map((tutorial, index) => (
            <m.div
              key={tutorial.title}
              initial={{
                opacity: 0,
                transform:
                  index % 2 === 0
                    ? 'translate3d(-10%, 0, 0)'
                    : 'translate3d(10%, 0, 0)'
              }}
              whileInView={{
                opacity: 1,
                transform: 'translate3d(0, 0, 0)'
              }}
              transition={{
                type: 'spring',
                duration: 0.8
              }}
              className="group flex cursor-pointer flex-col overflow-hidden rounded-lg border border-[#15254E] bg-[#15254E] hover:border-[#5C667A]"
            >
              <div className="relative overflow-hidden pb-[60%]">
                <div className="absolute inset-0 overflow-hidden transition-all  duration-500 ease-in-out  group-hover:scale-[1.12]">
                  {tutorial.coverImg}
                </div>
              </div>
              <div className="flex flex-1 flex-col justify-center gap-1 bg-[#0F1320] px-4 py-5">
                <h2 className="line-clamp-1 text-xl font-semibold leading-10">
                  {tutorial.title}
                </h2>
                <p className="line-clamp-3 text-sm text-[#5B6584]">
                  {tutorial.description}
                </p>
                <PrimaryButton className="mt-3 inline-block w-28 px-2 py-2 md:mt-3 md:py-1">
                  <span className="mr-1 text-sm">
                    {t('home.section.tutorial.category.card.buttonText')}
                  </span>
                  <ArrowLink className="mt-[2px]" />
                </PrimaryButton>
              </div>
            </m.div>
          ))}
        </div>
      </div>
      <m.div
        initial={{ opacity: 0, scale: 0.1 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{
          type: 'spring',
          duration: 0.8
        }}
        className="absolute -top-[25%] right-0 -z-10 hidden w-[75%] md:block 3xl:-top-[45%]"
      >
        <StaticImage
          src="../../../../assets/images/home/tutorial-category-gradient-vector.png"
          alt="Tutorial Category Gradient Vector"
          layout="fullWidth"
          objectFit="contain"
        />
      </m.div>
    </section>
  );
}
