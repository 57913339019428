import cn from 'classnames';
import { produce } from 'immer';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import ChoiceCheckedIcon from '@/assets/images/courses/choice-checked.inline.svg';
import ChoiceErrorIcon from '@/assets/images/courses/choice-error.inline.svg';
import ChoiceNormalIcon from '@/assets/images/courses/choice-normal.inline.svg';
import ChoiceRightIcon from '@/assets/images/courses/choice-right.inline.svg';
import { PrimaryButton } from '@/components/common/PrimaryButton';
import { useTranslation } from '@/i18n';

import { CompleteButton } from '../CompleteButton';
import { LessonStatus } from '../CourseDayDetailLayout';
interface IMCQProps {
  className?: string;
  questions?: API.QuizQuestion[];
  lessonStatus: LessonStatus;
  lessonData?: API.LessonDTO;
  courseId: string;
  refetchProgress: () => void;
}
type QuestionType = 'single' | 'multiple';
type ChoiceSubmittedStyle = 'uncheck' | 'right' | 'error';
interface IQuestionState {
  id: number;
  type: QuestionType;
  checkedIds: number[];
}
export function MCQ(props: IMCQProps) {
  const {
    className,
    questions = [],
    lessonStatus,
    lessonData,
    courseId,
    refetchProgress
  } = props;
  const { t } = useTranslation();
  const [questionState, setQuestionState] = useState<IQuestionState[]>([]);
  useEffect(() => {
    setQuestionState(
      questions.map((item) => ({
        id: item.id as number,
        type: (item.answers || []).length > 1 ? 'multiple' : 'single',
        checkedIds: []
      }))
    );
  }, [questions]);
  const [isSubmitted, setSubmitted] = useState(false);
  useEffect(() => {
    setSubmitted(lessonStatus === 'completed');
  }, [lessonStatus]);
  useEffect(() => {
    setQuestionState(
      questions.map((item) => ({
        id: item.id as number,
        type: item.multiple ? 'multiple' : 'single',
        checkedIds: lessonStatus === 'completed' ? item.answers || [] : []
      }))
    );
  }, [lessonStatus, questions]);

  const renderChoiceIcon = useCallback(
    (choiceChecked: boolean, choiceStyle: ChoiceSubmittedStyle) => {
      if (!isSubmitted) {
        if (choiceChecked) {
          return <ChoiceCheckedIcon />;
        } else {
          return <ChoiceNormalIcon />;
        }
      } else {
        if (choiceStyle === 'error') {
          return <ChoiceErrorIcon />;
        } else if (choiceStyle === 'right') {
          return <ChoiceRightIcon />;
        } else {
          return <ChoiceNormalIcon />;
        }
      }
    },
    [isSubmitted]
  );
  return (
    <div
      className={cn(
        className,
        'mx-auto flex w-full flex-col gap-y-5 px-4 pb-4 pt-10 lg:max-w-[1000px]'
      )}
    >
      {questions.map((question, questionIndex) => {
        const isLast = questionIndex === questions.length - 1;
        return (
          <div key={question.id as number}>
            <div
              className={cn(
                'pb-5',
                !isLast && 'border-b-[0.5px] border-solid border-[#FFFFFF33]'
              )}
            >
              <div className="flex items-start gap-x-2 sm:gap-x-4">
                <div className="flex h-5 w-5 items-center justify-center bg-[#256BEF] pt-1 text-sm font-medium leading-[1]">
                  {questionIndex + 1}
                </div>
                <span className="flex-1 text-base leading-5">
                  {question.question}
                </span>
              </div>
              <ul className="flex flex-col gap-y-2 px-[22px] py-[10px] sm:px-9">
                {question.choices?.map((choice, choiceIndex) => {
                  let choiceChecked = false;
                  if (
                    questionState
                      .find((qitem) => qitem.id === question.id)
                      ?.checkedIds.includes(choiceIndex)
                  ) {
                    choiceChecked = true;
                  }
                  let choiceStyle: ChoiceSubmittedStyle = 'uncheck';
                  if (isSubmitted) {
                    if (question.answers?.includes(choiceIndex)) {
                      choiceStyle = 'right';
                    } else {
                      if (choiceChecked) {
                        choiceStyle = 'error';
                      }
                    }
                  }
                  return (
                    <li
                      key={choice}
                      className={cn(
                        'flex items-start gap-x-2 text-sm',
                        !isSubmitted && 'cursor-pointer hover:text-[#256BEF]',
                        isSubmitted &&
                          choiceStyle === 'right' &&
                          'text-[#50FFC0]',
                        isSubmitted &&
                          choiceStyle === 'error' &&
                          'text-[#FA4D4D]'
                      )}
                      onClick={() => {
                        // 提交后不允许修改
                        if (isSubmitted) return;
                        // 选中状态变化、单选，多选
                        const nextState = produce(questionState, (draft) => {
                          const currentQuestionState = draft.find(
                            (qitem) => qitem.id === question.id
                          ) as IQuestionState;
                          const idIndex =
                            currentQuestionState?.checkedIds?.findIndex(
                              (idItem) => idItem === choiceIndex
                            );
                          if (question.multiple) {
                            // 多选的话，判断checkedIds是否包含当前，如果不包含，则添加、否则，则删除
                            if (idIndex >= 0) {
                              // 有值，需要删除
                              currentQuestionState.checkedIds.splice(
                                idIndex,
                                1
                              );
                            } else {
                              // 没有，需要新增
                              currentQuestionState.checkedIds.push(choiceIndex);
                            }
                          } else {
                            // 单选，直接更新 checkedIds 数组即可
                            if (idIndex >= 0) {
                              // 有值，直接取消
                              currentQuestionState.checkedIds = [];
                            } else {
                              // 没有值，直接更新
                              currentQuestionState.checkedIds = [choiceIndex];
                            }
                          }
                        });
                        setQuestionState(nextState);
                      }}
                    >
                      <div className="flex h-6 items-center pb-[6px]">
                        {renderChoiceIcon(choiceChecked, choiceStyle)}
                      </div>

                      <span>{choice}</span>
                    </li>
                  );
                })}
              </ul>
              {isSubmitted && (
                <p className="rounded-[10px] border-[1px] border-solid border-[#256BEF66] bg-[#256BEF26] px-2 py-[5px] text-sm leading-[1.5] sm:px-4 sm:py-[10px]">
                  <span className="text-[#50FFC0]">
                    {t('lesson.mcq.answer_analysis')}&nbsp;&nbsp;
                  </span>
                  {question.explanation}
                </p>
              )}{' '}
            </div>
          </div>
        );
      })}
      {isSubmitted ? (
        <CompleteButton
          lessonStatus={lessonStatus}
          lessonData={lessonData}
          courseId={courseId}
          refetchProgress={refetchProgress}
        />
      ) : (
        <PrimaryButton
          className="mx-auto mb-8 mt-2 min-w-[180px]"
          onClick={() => {
            // 检查用户是否选择
            for (const state of questionState) {
              if (state.checkedIds.length === 0) {
                toast.info(t('tip.info.please_select_answer_in_mcq'));
                return;
              }
            }
            setSubmitted(true);
          }}
        >
          {t('lesson.mcq.button.submit')}
        </PrimaryButton>
      )}
    </div>
  );
}
