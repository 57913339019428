import cn from 'classnames';
import React from 'react';

import { useAppStore } from '@/store/appStore';
interface IEditorCollapseProps {
  showEditor: boolean;
  setShowEditor: (show: boolean) => void;
}
export function EditorCollapse({
  showEditor,
  setShowEditor
}: IEditorCollapseProps) {
  const { splitPanelLeftRatio } = useAppStore();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="29"
      viewBox="0 0 18 29"
      fill="none"
      className={cn(
        'fixed top-[72px] z-10 hidden cursor-pointer hover:shadow sm:block'
      )}
      onClick={() => {
        setShowEditor(!showEditor);
      }}
      style={{
        right: showEditor
          ? splitPanelLeftRatio === 0.5
            ? 'calc(50% - 48px)'
            : `calc((100vw -  80px) * ${1 - splitPanelLeftRatio} - 8px)`
          : '0px'
      }}
    >
      <rect
        x="0.25"
        y="0.25"
        width="17.5"
        height="28.5"
        rx="3.75"
        fill="currentColor"
        stroke="#6F788A"
        strokeWidth="0.5"
        className="text-[#4F596C] duration-200 hover:text-primaryColor"
      />
      <path
        d="M6 9L11.6569 14.6569L6 20.3137"
        stroke="white"
        className={cn(!showEditor && 'origin-center rotate-180')}
      />
    </svg>
  );
}
