export default {
  'lesson.back_button.courses': '列表页',
  'lesson.back_button.home': '首页',

  'lesson.button.next': '下一课',
  'lesson.button.make_complete': '完成',

  'lesson.mcq.button.submit': '提交',
  'lesson.mcq.answer_analysis': '答案解析：'
};
