import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

interface ISocialInfo {
  userId: string;
  username: string;
  token?: string;
}

interface IUserInfo {
  userId: string;
  username: string;
  email?: string;
  githubToken?: string;
  authorities: string[];
  description?: string;
  avatar?: string;
  banner?: string;
  twitterInfo?: ISocialInfo;
  discordInfo?: ISocialInfo;
  githubInfo?: ISocialInfo;
  hasPassword: boolean;
}
interface AppState {
  showEditor?: boolean;
  setShowEditor: (show: boolean) => void;
  splitPanelLeftRatio: number;
  setSplitPanelLeftRatio: (ratio: number) => void;
  isDragging?: boolean;
  setDragging: (isDrag: boolean) => void;
  editorWorkspaceId?: string;
  setEditorWorkspaceId: (id?: string) => void;
  editorChain?: string;
  setEditorChain: (chain?: string) => void;
  userInfo?: IUserInfo;
  setUserInfo: (userInfo?: IUserInfo) => void;
  logining: boolean;
  setLogining: (val: boolean) => void;
}

// 默认集成了redux devtool方便观察数据流，immer做数据不可变，persist做数据持久化
export const useAppStore = create<AppState>()(
  persist(
    devtools(
      immer((set, get) => ({
        // 通过set来更新state，通过get() 可以获取到当前最新值
        showEditor: true,
        setShowEditor(show) {
          set(
            (state) => {
              state.showEditor = show;
            },
            false,
            'setShowEditor'
          );
        },
        splitPanelLeftRatio: 0.5,
        setSplitPanelLeftRatio(ratio) {
          set(
            (state) => {
              state.splitPanelLeftRatio = ratio;
            },
            false,
            'setMarkdownContainerWidth'
          );
        },
        setDragging(isDrag) {
          set(
            (state) => {
              state.isDragging = isDrag;
            },
            false,
            'setDragging'
          );
        },
        setEditorChain(chain) {
          set(
            (state) => {
              state.editorChain = chain;
            },
            false,
            'setEditorChain'
          );
        },
        setEditorWorkspaceId(id) {
          set(
            (state) => {
              state.editorWorkspaceId = id;
            },
            false,
            'setEditorWorkspaceId'
          );
        },
        setUserInfo(userInfo) {
          set(
            (state) => {
              state.userInfo = userInfo;
            },
            false,
            'setUserInfo'
          );
        },
        logining: false,
        setLogining(val) {
          set(
            (state) => {
              state.logining = val;
            },
            false,
            'setLogining'
          );
        }
      }))
    ),
    {
      name: 'edu-app-store',
      // 保存项
      partialize: (state) => ({
        showEditor: state.showEditor,
        splitPanelLeftRatio: state.splitPanelLeftRatio
      })
    }
  )
);
