import cn from 'classnames';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import DownArrow from '@/assets/images/courses/step-down-arrow.inline.svg';
import UpArrow from '@/assets/images/courses/step-up-arrow.inline.svg';
import { DEFAULT_SECTION_SEQUENCE, ESearchParamKeys } from '@/utils/const';
import { useGatsbySearchParam, useSize } from '@/utils/hooks';

type SectionStatus = 'learnt' | 'active' | 'not_start' | '';

interface DayStepsProps {
  className?: string;
  currentStep?: number;
  setCurrentStep: (step: number) => void;
  totalSteps: number;
  showLessonList: boolean;
  toggleShowLessonList: (show?: boolean) => void;
  courseProgress?: API.CourseProgressDTO;
  chapterSequence?: number;
}

export function DaySteps(props: DayStepsProps) {
  const {
    className,
    currentStep,
    totalSteps,
    toggleShowLessonList,
    showLessonList,
    setCurrentStep,
    courseProgress,
    chapterSequence
  } = props;
  const sectionSequence =
    useGatsbySearchParam(ESearchParamKeys.SECTION_SEQUENCE) ||
    DEFAULT_SECTION_SEQUENCE;
  const { height } = useSize();
  const ref = useRef<HTMLDivElement | null>(null);
  const stepConfigs = useMemo(() => {
    const result: Array<{ step: number; stepLen: number }> = [];
    for (let i = 0; i < totalSteps; i++) {
      result.push({
        step: i + 1,
        stepLen: height / 20
      });
    }
    return result;
  }, [height, totalSteps]);
  const [hasScroll, setHasScroll] = useState(false);

  useEffect(() => {
    setHasScroll(
      !!ref.current && ref.current.scrollHeight > ref.current.offsetHeight
    );
  }, [stepConfigs]);

  useEffect(() => {
    if (!chapterSequence || !sectionSequence) return;
    const sectionEle = document.getElementById(
      `${chapterSequence || ''}_${sectionSequence.toString()}`
    );
    if (sectionEle) {
      sectionEle.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chapterSequence, sectionSequence, stepConfigs]);

  const renderSteps = useCallback(() => {
    return (
      <div
        ref={ref}
        className="fixed bottom-[30px] left-[14px] top-[102px] h-[calc(100vh_-_132px)] overflow-auto sm:left-[18px]"
      >
        {stepConfigs.map((item) => {
          let sectionStatus: SectionStatus = '';
          if (
            sectionSequence &&
            courseProgress?.chapterSequence &&
            courseProgress?.sectionSequence &&
            chapterSequence
          ) {
            if (
              Number(sectionSequence) === item.step &&
              chapterSequence <= courseProgress.chapterSequence
            ) {
              sectionStatus = 'active';
            } else if (
              chapterSequence < courseProgress.chapterSequence ||
              (item.step <= courseProgress.sectionSequence &&
                chapterSequence === courseProgress.chapterSequence)
            ) {
              sectionStatus = 'learnt';
            } else {
              sectionStatus = 'not_start';
            }
          }
          return (
            <div
              id={`${chapterSequence || ''}_${item.step.toString()}`}
              key={item.step}
              className="flex flex-col items-center justify-center gap-1"
            >
              <div
                onClick={() => {
                  if (item.step !== currentStep) {
                    toggleShowLessonList(true);
                  } else {
                    toggleShowLessonList();
                  }
                  setCurrentStep(item.step);
                }}
                className={cn(
                  'relative flex h-9 w-9 cursor-pointer items-center justify-center rounded-lg border-[1px] border-solid transition-all sm:h-11 sm:w-11',
                  sectionStatus === 'learnt' &&
                    'border-primaryColor text-primaryColor',
                  sectionStatus === 'not_start' &&
                    'border-[#CDD5E0] text-[#CDD5E0]',
                  sectionStatus === 'active' &&
                    'border-primaryColor bg-primaryColor text-white',
                  'hover:!border-none hover:!bg-primaryColor hover:!text-white',
                  currentStep === item.step &&
                    showLessonList &&
                    '!border-none !bg-primaryColor !text-white'
                )}
              >
                <span className="text-base font-semibold !leading-[1] sm:text-lg sm:font-medium ">
                  {item.step}
                </span>
                {sectionStatus === 'active' && (
                  <div className="absolute bottom-[3px] left-[50%] h-[2px] w-5 -translate-x-[50%] rounded-full bg-[#FFFFFF] sm:bottom-[5px] sm:w-[26px]"></div>
                )}
              </div>
              {item.step !== stepConfigs.length && (
                <div
                  className={cn(
                    'my-2 w-0 border transition-all',
                    sectionStatus === '' || sectionStatus === 'not_start'
                      ? 'border-dashed border-[#697D9B]'
                      : chapterSequence === courseProgress?.chapterSequence &&
                        item.step === courseProgress?.sectionSequence
                      ? 'border-dashed border-[#256BEF]'
                      : 'border-solid border-[#256BEF]'
                  )}
                  style={{ height: `${item.stepLen}px` }}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }, [
    chapterSequence,
    courseProgress?.chapterSequence,
    courseProgress?.sectionSequence,
    currentStep,
    sectionSequence,
    setCurrentStep,
    showLessonList,
    stepConfigs,
    toggleShowLessonList
  ]);

  return (
    <div className={cn(className, 'relative h-full overflow-auto')}>
      {renderSteps()}
      {hasScroll && (
        <>
          <div
            className={cn(
              'fixed left-0 top-[72px] z-10 flex h-[30px] w-16 items-center justify-center bg-[#131C28] sm:w-20'
            )}
            onClick={() => {
              ref.current?.scrollTo({ top: 0, behavior: 'smooth' });
            }}
          >
            <UpArrow className="cursor-pointer text-[#CDD5E0] duration-200 hover:text-primaryColor" />
          </div>
          <div
            className={cn(
              'fixed bottom-0 left-0 flex h-[30px] w-16 items-center justify-center bg-[#131C28] sm:w-20'
            )}
          >
            <DownArrow
              className="cursor-pointer text-[#CDD5E0] duration-200 hover:text-primaryColor"
              onClick={() => {
                ref.current?.scrollTo({
                  top: ref.current.scrollHeight,
                  behavior: 'smooth'
                });
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}
