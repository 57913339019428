import cn from 'classnames';
import { Link, navigate } from 'gatsby';
import React from 'react';

import DiscordIcon from '@/assets/images/common/footer/discord.inline.svg';
import FaceBookIcon from '@/assets/images/common/footer/facebook.inline.svg';
import GithubIcon from '@/assets/images/common/footer/github.inline.svg';
import MediumIcon from '@/assets/images/common/footer/medium.inline.svg';
import TelegramIcon from '@/assets/images/common/footer/telegram.inline.svg';
import TwitterIcon from '@/assets/images/common/footer/twitter.inline.svg';
import YoutubeIcon from '@/assets/images/common/footer/youtube.inline.svg';
import EduLogo from '@/assets/images/edu-logo.inline.svg';
import { useTranslation } from '@/i18n';

const currentYear = new Date().getFullYear();
const socials = [
  {
    icon: MediumIcon,
    link: 'https://medium.com/white-matrix'
  },
  {
    icon: GithubIcon,
    link: 'https://github.com/WhiteMatrixTech'
  },
  {
    icon: FaceBookIcon,
    link: 'https://www.facebook.com/MatrixDapp/'
  },
  {
    icon: TwitterIcon,
    link: 'https://twitter.com/ChainIde'
  },
  {
    icon: TelegramIcon,
    link: 'https://t.me/ChainIDEdevelopers'
  },
  {
    icon: YoutubeIcon,
    link: 'https://www.youtube.com/channel/UCgvPUHayWfxAGiJCI2xOzNg'
  },
  {
    icon: DiscordIcon,
    link: 'https://discord.com/invite/qv8M29pYwX'
  }
  // {
  //   icon: BilibiliIcon,
  //   link: 'https://space.bilibili.com/2021302173'
  // }
];

const links = [
  {
    title: 'footer.menu.learn',
    subs: [
      {
        subtitle: 'footer.menu.learn.sub.tutorial',
        // href: "/tutorial"
        href: ' '
      },
      // {
      //   subtitle: 'footer.menu.learn.sub.course',
      //   // href: "/courses",
      //   href: '/courses'
      // },
      {
        subtitle: 'footer.menu.build',
        href: ''
      },
      {
        subtitle: 'footer.menu.build.sub.studio',
        href: 'http://chainide.com/'
      },
      {
        subtitle: 'footer.menu.build.sub.extensions',
        // href: MARKETPLACE_DOMAIN
        href: ' '
      }
    ]
  },

  {
    title: 'footer.menu.events',
    subs: [
      {
        subtitle: 'footer.menu.events.sub.bootcamp',
        href: 'https://chainide.com/events/bootcamp'
      },
      {
        subtitle: 'footer.menu.events.sub.hackathon',
        href: 'https://chainide.com/events/hackathon'
      },
      {
        subtitle: 'footer.menu.events.sub.workshop',
        href: 'https://chainide.com/events/workshop-seminar'
      },
      {
        subtitle: 'footer.menu.events.sub.seminar',
        href: 'https://chainide.com/events/workshop-seminar'
      },
      {
        subtitle: 'footer.menu.events.sub.summit',
        href: 'https://chainide.com/events/developer-summit'
      }
    ]
  },
  {
    title: 'footer.menu.community',
    subs: [
      {
        subtitle: 'footer.menu.community.sub.forum',
        href: 'https://forum.chainide.com/'
      },
      {
        subtitle: 'footer.menu.community.sub.discord',
        href: 'https://discord.com/invite/qv8M29pYwX'
      },
      {
        subtitle: 'footer.menu.community.sub.twitter',
        href: 'https://twitter.com/ChainIde'
      },
      {
        subtitle: 'footer.menu.community.sub.telegram',
        href: 'https://t.me/ChainIDEdevelopers'
      },
      {
        subtitle: 'footer.menu.community.sub.facebook',
        href: 'https://www.facebook.com/MatrixDapp/'
      }
    ]
  },
  {
    title: 'footer.menu.resources',
    subs: [
      {
        subtitle: 'footer.menu.resources.sub.blog',
        href: 'https://medium.com/white-matrix'
      },
      {
        subtitle: 'footer.menu.resources.sub.guides',
        href: 'https://chainide.gitbook.io/chainide-chinese/chainide/1.-ethereum-ide'
      },
      {
        subtitle: 'footer.menu.resources.sub.docs',
        href: 'https://chainide.gitbook.io/chainide-english-1/'
      },
      {
        subtitle: 'footer.menu.resources.sub.videos',
        href: 'https://www.youtube.com/channel/UCgvPUHayWfxAGiJCI2xOzNg'
      },
      {
        subtitle: 'footer.menu.resources.sub.osc',
        href: 'https://github.com/WhiteMatrixTech'
      }
    ]
  },
  {
    title: 'footer.menu.company',
    subs: [
      {
        subtitle: 'footer.menu.company.sub.about',
        href: 'https://whitematrix.io/?lang=en'
      },
      {
        subtitle: 'footer.menu.company.sub.contact',
        href: 'https://t.me/ChainIDEdevelopers'
      }
    ]
  }
];
export function Footer() {
  const { t } = useTranslation();
  return (
    <div className="w-full bg-[#000000ff] pt-20 sm:pt-40 ">
      <footer
        className={cn('page-layout !min-h-[0px] pb-8 pt-[98px] text-[#97A2B8]')}
      >
        <div className="flex flex-wrap items-start justify-between border-b-[1px] border-solid border-[#424b59ff] pb-8">
          <div className="pb-10">
            <EduLogo className="mb-3 origin-left scale-[0.5]" />
            <div className="flex items-center gap-x-[15px]">
              {socials.map((social) => (
                <a
                  href={social.link}
                  target="_blank"
                  key={social.link}
                  className="flex h-6 w-6 items-center justify-center rounded bg-[#191919ff]"
                  rel="noreferrer"
                >
                  <social.icon />
                </a>
              ))}
            </div>
          </div>
          <div className="flex flex-row flex-wrap gap-x-[28px] xl:gap-x-[56px]">
            {links.map((item) => (
              <div
                className={cn(
                  'mb-6 flex w-[160px] flex-col gap-y-3 sm:mb-[18px] sm:w-auto sm:gap-y-[18px]'
                )}
                key={item.title}
              >
                <div className="font-medium text-white">{t(item.title)}</div>
                {item.subs.map((sub) => (
                  <div
                    key={sub.subtitle}
                    onClick={() => {
                      if (sub.href) {
                        if (sub.href.includes('http')) {
                          window.open(sub.href, '_blank');
                        }
                        if (sub.href.startsWith('/')) {
                          void navigate(sub.href);
                        }
                      }
                    }}
                    className={cn(
                      sub.href === ''
                        ? 'font-medium text-white'
                        : 'cursor-pointer text-sm leading-[24px] hover:text-white'
                    )}
                  >
                    {t(sub.subtitle)}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <p className="my-4 text-center text-base">
          © {currentYear} White Matrix Ltd. | All rights reserved
        </p>
        <div className="flex items-center justify-center gap-x-5 text-sm">
          <Link
            to="/tos"
            className="block h-5 w-[100px] text-center leading-5 duration-200 hover:font-medium hover:text-white"
          >
            {t('footer.tos')}
          </Link>
          <Link
            to="/privacy"
            className="block h-5 w-[100px] text-center leading-5  duration-200 hover:font-medium hover:text-white"
          >
            {t('footer.privacy')}
          </Link>
        </div>
      </footer>
    </div>
  );
}
