/* eslint-disable @typescript-eslint/no-explicit-any */
import store from 'store2';

import { EStorageKeys } from './const';

interface ITokenData {
  accessToken: string;
  refreshToken: string;
  /**
   * 单位秒
   */
  expiresIn: number;
  scope: string;
  tokenType: string;
}

export function getTokenData() {
  return store.get(EStorageKeys.TOKEN_DATA) as ITokenData | undefined;
}

export function saveTokenData(data: any) {
  store.set(EStorageKeys.TOKEN_DATA, data);
}

export function removeTokenData() {
  store.remove(EStorageKeys.TOKEN_DATA);
}
