import cn from 'classnames';
import { m } from 'framer-motion';
import React, { ReactNode } from 'react';

import LoadSVG from '@/assets/images/courses/loading.inline.svg';
interface PrimaryButtonProps {
  className?: string;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  children: ReactNode;
  loading?: boolean;
  disabled?: boolean;
}

export function PrimaryButton(props: PrimaryButtonProps) {
  const {
    children,
    className,
    type,
    onClick,
    loading = false,
    disabled = false
  } = props;

  return (
    <m.button
      disabled={loading || disabled}
      className={cn(
        (loading || disabled) && '!cursor-not-allowed !bg-opacity-80',
        'flex items-center justify-center rounded bg-primaryColor px-4 py-2 font-Manrope text-base font-medium leading-[18px] transition hover:bg-opacity-80 hover:text-white/80',
        className
      )}
      onClick={() => {
        if (!loading && !disabled && onClick) {
          onClick();
        }
      }}
      type={type}
    >
      {children}
      {loading && <LoadSVG className="ml-2 h-4 w-4" />}
    </m.button>
  );
}
