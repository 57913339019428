/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { m, Variants } from 'framer-motion';
import { navigate } from 'gatsby';
import React, { useRef, useState } from 'react';
import { useClickAway } from 'react-use';

import ArrowDown from '@/assets/images/common/arrow-down.inline.svg';
import { Avatar } from '@/components';
import { useTranslation } from '@/i18n';
import { logoutEmitter } from '@/services/request';
import { useAppStore } from '@/store/appStore';
import { login } from '@/utils/common';
import { isMoveCastle } from '@/utils/const';

import { PrimaryButton } from '../../PrimaryButton';

const containerVariants: Variants = {
  open: {
    clipPath: 'inset(0% 0% 0% 0% round 10px)',
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.5,
      delayChildren: 0.15,
      staggerChildren: 0.05
    }
  },
  closed: {
    clipPath: 'inset(10% 50% 90% 50% round 10px)',
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.3
    }
  }
};
const itemVariants: Variants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: 'spring', stiffness: 300, damping: 24 }
  },
  closed: { opacity: 0, y: 20, transition: { duration: 0.2 } }
};

export function Account() {
  const { t } = useTranslation();
  const { userInfo, logining } = useAppStore();
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef<HTMLElement>(null);
  useClickAway(ref, () => {
    setIsOpen(false);
  });

  return (
    <div className="block">
      {userInfo ? (
        <m.nav
          className="relative"
          ref={ref}
          initial={false}
          animate={isOpen ? 'open' : 'closed'}
        >
          <m.button
            whileTap={{ scale: 0.97 }}
            onClick={() => setIsOpen(!isOpen)}
            className="relative flex h-9 items-center justify-center gap-2 duration-200 hover:opacity-75"
          >
            <Avatar size="small" />
            <m.div
              variants={{
                open: { rotate: 180 },
                closed: { rotate: 0 }
              }}
              transition={{ duration: 0.2 }}
              style={{ originY: 0.55 }}
            >
              <ArrowDown />
            </m.div>
          </m.button>
          <m.ul
            variants={containerVariants}
            style={{ pointerEvents: isOpen ? 'auto' : 'none' }}
            className="absolute -right-5 top-[54px] z-10 w-[140px] rounded bg-[#1F2F4D]"
          >
            {!isMoveCastle && (
              <m.li
                role="button"
                variants={itemVariants}
                onClick={() => {
                  setIsOpen(false);
                  void navigate('/profile');
                }}
                className="px-5 py-4 text-lg leading-[1] hover:bg-[rgba(117,161,244,0.5)] hover:text-white/80"
              >
                {t('header.button.profile')}
              </m.li>
            )}
            <m.li
              role="button"
              variants={itemVariants}
              onClick={() => {
                logoutEmitter.fire();
              }}
              className="px-5 py-4 text-lg leading-[1] hover:bg-[rgba(117,161,244,0.5)] hover:text-white/80"
            >
              {t('header.button.logout')}
            </m.li>
          </m.ul>
        </m.nav>
      ) : (
        <PrimaryButton className="px-5" loading={logining} onClick={login}>
          {t('header.button.login')}
        </PrimaryButton>
      )}
    </div>
  );
}
