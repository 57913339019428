import { useMemo } from 'react';
import { useWindowSize } from 'react-use';

export function useSize(mobileWidth?: number) {
  const { width, height } = useWindowSize();
  const isMIN = useMemo(() => {
    return width < 640;
  }, [width]);
  const isSM = useMemo(() => {
    return width >= 640 && width < 768;
  }, [width]);
  const isMD = useMemo(() => {
    return width >= 768 && width < 1024;
  }, [width]);
  const isLG = useMemo(() => {
    return width >= 1024 && width < 1280;
  }, [width]);
  const isXL = useMemo(() => {
    return width >= 1280 && width < 1540;
  }, [width]);
  const is2XL = useMemo(() => {
    return width >= 1540;
  }, [width]);
  /**
   * 通过屏幕宽度是否mobile
   */
  const isMobile = useMemo(
    () => width < (mobileWidth || 1024),
    [mobileWidth, width]
  );
  return { isMIN, isSM, isMD, isLG, isXL, is2XL, isMobile, width, height };
}
