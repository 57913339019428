import cn from 'classnames';
// import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import basecampStarPoster from '@/assets/images/home/basecamp-stars-poster.png';
import basecampStarTrackVideo from '@/assets/videos/basecamp-star-track.mp4';

import { Basecamp } from './Basecamp';
import * as style from './SectionBasecamp.module.css';

interface BasecampProps {
  className?: string;
}

export function SectionBasecamp(props: BasecampProps) {
  const { className } = props;

  return (
    <div className={cn('relative pb-[56px] 3xl:mt-48', className)}>
      <div className={cn(style.starBg, 'absolute inset-0 -z-50')} />
      <div className="absolute inset-0 -z-50 -mt-[5%] hidden justify-center overflow-hidden opacity-80 md:flex 3xl:-mt-[8%]">
        {/* <img
          src="images/home/basecamp-star-track.gif"
          alt="Basecamp Star Track"
          className="scale-x-125 3xl:scale-105"
        /> */}
        <video
          loop={true}
          autoPlay={true}
          muted={true}
          controls={false}
          poster={basecampStarPoster}
          className="w-screen object-cover"
        >
          <source src={basecampStarTrackVideo} type="video/mp4"></source>
        </video>
      </div>
      <Basecamp />
      {/* <div className="relative -z-50 -mb-[456px] h-[456px]" /> */}
    </div>
  );
}
