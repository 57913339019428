import cn from 'classnames';
import React, { ReactNode } from 'react';

import Icon from '@/assets/images/courses/target-icon.inline.svg';

interface ICardLayoutProps {
  className?: string;
  children: ReactNode;
  title: string;
}
export function CardLayout(props: ICardLayoutProps) {
  const { className, children, title } = props;
  return (
    <div className={cn(className, 'flex w-full flex-col')}>
      <div className="mb-5 flex w-fit items-center gap-x-2 border-b-[2px] border-solid border-primaryColor">
        <Icon />
        <span className="text-xl font-[500] leading-[1.5] text-white">
          {title}
        </span>
      </div>
      {children}
    </div>
  );
}
