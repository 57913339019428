/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import cn from 'classnames';
import Clipboard from 'clipboard';
import highlight from 'highlight.js';
import hljs from 'highlight.js/lib/core';
import charp from 'highlight.js/lib/languages/csharp';
import css from 'highlight.js/lib/languages/css';
import java from 'highlight.js/lib/languages/java';
import javascript from 'highlight.js/lib/languages/javascript';
import json from 'highlight.js/lib/languages/json';
import less from 'highlight.js/lib/languages/less';
import scss from 'highlight.js/lib/languages/scss';
import shell from 'highlight.js/lib/languages/shell';
import typescript from 'highlight.js/lib/languages/typescript';
import hljsDefineSolidity from 'highlightjs-solidity';
import { escape } from 'html-escaper';
import { marked } from 'marked';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';

import { openLinksInNewTab } from '@/utils/common';
interface IMarkdownProps {
  className?: string;
  markdown?: string;
  title: string;
}

export function MarkDown(props: IMarkdownProps) {
  const { className, markdown, title } = props;
  const [html, setHtml] = useState('');
  useEffect(() => {
    if (markdown) {
      setHtml(markdownToHtml(markdown));
    }
  }, [markdown]);

  useEffect(() => {
    // 创建Clipboard实例，监听复制成功事件
    const clipboard = new Clipboard('.copy-button');
    clipboard.on('success', (e) => {
      const beforeHtml = e.trigger.innerHTML;
      // 设置复制按钮的文本为“已复制”
      e.trigger.innerHTML = `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="26" height="26" rx="4" fill="#256BEF"/>
      <path d="M18 10L11.5999 16L8 12.147" stroke="white"/>
      </svg>
      `;
      // 2秒后恢复原文本
      setTimeout(() => {
        e.trigger.innerHTML = beforeHtml;
      }, 2000);
      // 清除剪贴板的内容
      e.clearSelection();
    });
    // 在组件卸载前销毁Clipboard实例
    return () => {
      clipboard.destroy();
    };
  });

  useEffect(() => {
    if (html) {
      registerCode();
    }
  }, [html]);

  return (
    <div
      className={cn(
        'mx-auto w-full pb-4 lg:max-w-[1000px] 2xl:max-w-[1440px]',
        className
      )}
    >
      <h2 className="my-6 text-center text-lg font-bold leading-[1.5] text-white sm:text-2xl 3xl:my-10 3xl:text-[26px]">
        {title}
      </h2>
      <article className="!prose !prose-invert !w-full !max-w-none 3xl:!prose-lg prose-headings:!text-[#cdd5e0] prose-p:!text-[#97A2B8] prose-strong:!text-[#cdd5e0] prose-code:!font-mono prose-code:before:!hidden prose-code:after:!hidden prose-li:!text-[#97A2B8]">
        <div
          className="markdown"
          dangerouslySetInnerHTML={{
            __html: html
          }}
        />
      </article>
    </div>
  );
}

function markdownToHtml(markdown: string) {
  try {
    const renderer = new marked.Renderer();
    // 重写代码块的渲染方法，添加复制按钮和data-clipboard-target属性
    renderer.code = (code, language) => {
      // 生成一个随机的id
      const id = `code-${nanoid()}`;
      const validLanguage = !!(language && highlight.getLanguage(language));
      let highlightedCode: string = validLanguage
        ? highlight.highlight(language, code).value
        : code;
      if (language === 'move') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        highlightedCode = escape(highlightedCode);
      }
      return `
        <div class="code-block relative">
          <pre><code class="${
            language || ''
          }" id="${id}">${highlightedCode}</code></pre>
          <button class="copy-button absolute right-4 text-sm top-4" data-clipboard-target="#${id}" title="copy">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="26" height="26" rx="4" fill="#131C28"/>
              <path d="M14.6001 11.3995V18.5996H7.40001V11.3995H14.6001ZM15.0501 10.4995H6.95C6.83066 10.4995 6.7162 10.5469 6.6318 10.6313C6.54741 10.7157 6.5 10.8302 6.5 10.9495V19.0496C6.5 19.169 6.54741 19.2834 6.6318 19.3678C6.7162 19.4522 6.83066 19.4996 6.95 19.4996H15.0501C15.1694 19.4996 15.2839 19.4522 15.3683 19.3678C15.4527 19.2834 15.5001 19.169 15.5001 19.0496V10.9495C15.5001 10.8302 15.4527 10.7157 15.3683 10.6313C15.2839 10.5469 15.1694 10.4995 15.0501 10.4995Z" fill="white"/>
              <path d="M9.64922 13.2007H12.3493C12.6493 13.2007 12.7993 13.3507 12.7993 13.6507C12.7993 13.9507 12.6493 14.1007 12.3493 14.1007H9.64922C9.34922 14.1007 9.19922 13.9507 9.19922 13.6507C9.19922 13.3507 9.34922 13.2007 9.64922 13.2007Z" fill="white"/>
              <path d="M9.64922 15.9004H12.3493C12.6493 15.9004 12.7993 16.0504 12.7993 16.3504C12.7993 16.6504 12.6493 16.8004 12.3493 16.8004H9.64922C9.34922 16.8004 9.19922 16.6504 9.19922 16.3504C9.19922 16.0504 9.34922 15.9004 9.64922 15.9004Z" fill="white"/>
              <path d="M17.7493 7.7998H9.64922C9.52987 7.7998 9.41541 7.84722 9.33102 7.93161C9.24663 8.016 9.19922 8.13046 9.19922 8.24981V9.59982H10.0992V8.69981H17.2993V16.7999H16.3993V17.6999H17.7493C17.8687 17.6999 17.9831 17.6525 18.0675 17.5681C18.1519 17.4837 18.1993 17.3693 18.1993 17.2499V8.24981C18.1993 8.13046 18.1519 8.016 18.0675 7.93161C17.9831 7.84722 17.8687 7.7998 17.7493 7.7998Z" fill="white"/>
            </svg>
          </button>
        </div>
      `;
    };
    return openLinksInNewTab(marked(markdown, { renderer }));
  } catch (e) {
    console.error(e);
    return '';
  }
}

function registerCode() {
  hljsDefineSolidity(hljs);
  hljs.initHighlightingOnLoad();
  hljs.registerLanguage('jsx', javascript);
  hljs.registerLanguage('js', javascript);
  hljs.registerLanguage('javascript', javascript);
  hljs.registerLanguage('java', java);
  hljs.registerLanguage('csharp', charp);
  hljs.registerLanguage('ts', typescript);
  hljs.registerLanguage('typescript', typescript);
  hljs.registerLanguage('shell', shell);
  hljs.registerLanguage('css', css);
  hljs.registerLanguage('less', less);
  hljs.registerLanguage('scss', scss);
  hljs.registerLanguage('json', json);
  hljs.highlightAll();
}
